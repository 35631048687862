import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import strings from "../../../Localization/LNG";
import { Dropdown, Button } from "semantic-ui-react";
import enstanse from "../../../utils/axios";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import { getingCompare } from "../../../redux/actions/compareActions";
import qs from "qs";
import { Space, Checkbox } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import CultureReview from "./../CultureReview";
import BenefitReview from "./../benifitsReview";
// import { Form } from "react-bootstrap";
import DynamicForm from "./../dynamic-from";

import CustomDropDown from "../../../Components/custom-dropdown";
import { toast } from "react-toastify";
import { Form, Row, Col, Radio, Rate, Input } from "antd";
import Select from "../../../Shared/CustomComponents/Select/Select";
var month = [
  { name: "January", key: "January", id: "January" },
  { name: "February", key: "February", id: "February" },
  { name: "March", key: "March", id: "March" },
  { name: "April", key: "April", id: "April" },
  { name: "May", key: "May", id: "May" },
  { name: "April", key: "April", id: "April" },
  { name: "June", key: "June", id: "June" },
  { name: "July", key: "July", id: "July" },
  { name: "September", key: "September", id: "September" },
  { name: "October", key: "October", id: "October" },
  { name: "November", key: "November", id: "November" },
  { name: "December", key: "December", id: "December" },
];
const { Option } = Select;
const currentYear = new Date().getFullYear();
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const rangedValues = range(currentYear, currentYear - 50, -1);
let years = [];
rangedValues.forEach((n) => years.push({ id: n, name: n, key: n }));

const yearOptions = years;

export default function Step3({ step, data, company, benefits }) {
  const [nationalities, setNationalities] = useState([]);
  const [citys, setCitys] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const [specilizations, setSpecilizations] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const [getBouns, setGetBouns] = useState(1);
  const dispath = useDispatch();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const getNationalities = async () => {
    const res = await enstanse.get(`/nationalities`);
    setNationalities(res.data.data);
  };
  const [ex, setEx] = useState([]);
  const [questionsDynamic, setQuestionsDynamic] = useState([]);

  const getExpe = () => {
    enstanse.get(`/agegroup/workexperience`).then((res) => {
      setEx(res.data.workexperience);
    });
  };
  const getCountrys = async () => {
    const res = await enstanse.get("/countries");
    setCountrys(res.data.data);
  };

  const getCitys = async (value) => {
    const params = {};
    if (value) {
      params.country_id = value;
    }
    const res = await enstanse.get("/cities", {
      params,
    });
    setCitys(res.data.data);
  };

  const getQ = () => {
    enstanse.get(`/qualifications`).then((res) => {
      setQualifications(res.data.data);
    });
  };

  const getSpecilizations = (id) => {
    enstanse.get(`/qualifications/${id}/specilizations`).then((res) => {
      setSpecilizations(res.data.data);
    });
  };

  useEffect(() => {
    getNationalities();
    getExpe();
    getCountrys();
    getQ();
    getCitys();
    // new
    getStages();
    getCurrencys();
  }, []);

  const [culturetype, setCultureType] = useState("careerpath");
  // interview
  const [interviewTest, setInterviewTest] = useState([]);
  const [interviewTypes, setInterviewTypes] = useState([]);
  const [others, setOthers] = useState([]);
  const getStages = async () => {
    if (data.review_type == "interview") {
      const res = await enstanse.get("/interview/stages");
      const data = res.data.data;
      const { interview, other, testing } = data;
      setInterviewTest(interview);
      setInterviewTypes(testing);
      setOthers(other);
      // setStages(dep);
    }
  };

  const [lang, setLang] = useState(window.localStorage.getItem("lng"));
  const [currencys, setCurrencys] = useState([]);
  const getCurrencys = async () => {
    if (data.review_type == "salary") {
      const res = await enstanse.get("/curriencies");
      const data = res.data.data;
      setCurrencys(data);
    }
  };

  const { type, id } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  return (
    benefits && (
      <div>
        <Form
          name="basic"
          layout="vertical"
          onFinish={(valuesParam) => {
            const values = {
              ...data,
              ...valuesParam,
            };
            console.log({ values });
            let url;
            if (data.review_type === "salary") {
              url = "/salary/review";
            } else if (data.review_type === "company") {
              url = "/company/review";
            } else if (data.review_type === "interview") {
              url = "/interview/review";
              delete values.country_id;
              // delete values.employment_status;
              // delete values.interviewOther;
              // delete values.interviewtesting;
              delete values.review_type;
            } else if (data.review_type == "culture") {
              if (culturetype === "careerpath") {
                url = "/careerpath/review";
              } else if (culturetype === "leadership") {
                url = "/leadership/review";
              } else if (culturetype === "environment") {
                url = "/environment/review";
              }
            } else if (data.review_type === "benifit") {
              values.city_id = values.cities;
              url = "/benefit/review";
              values.benefits = values?.benefits?.map(
                ({ benefit_id, offered }) => ({ offered, benefit_id })
              );
              delete values.country_id;
              delete values.cities;
              // delete values.employment_status;
              delete values.interviewOther;
              delete values.interviewtesting;
              delete values.interviewtype;
              delete values.review_type;
            }

            const selectedStages = [];
            if (values.interviewtype) {
              selectedStages.push(values.interviewtype);
              delete values.interviewtype;
            }
            if (values.interviewtesting) {
              selectedStages.push(values.interviewtesting);
            }
            if (values.interviewOther) {
              selectedStages.push(values.interviewOther);
            }
            values.stages = selectedStages;

            values.accept_terms = +values.accept_terms;

            console.log({ values });

            toast.promise(
              enstanse.post(url, values, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }),
              {
                pending: isAr ? "جاري المعالجة " : "Pending",
                success: {
                  render(res) {
                    setSubmitting(false);
                    history.push(`/company/${id}`);

                    return `👌 ${res.data.data.message}`;
                  },
                  // other options
                  icon: "🟢",
                },
                error: {
                  render(err) {
                    setSubmitting(false);
                    return `  ${
                      err?.data?.response?.data?.message ||
                      "Backend Error Occured"
                    }`;
                  },
                  // other options
                  //    icon: "🟢",
                },
              }
            );
          }}
          initialValues={{
            nationality: "",
            city: "",
            country_id: 133,
            specilization: "",
            education: "",
            gender: "",
            education: "",
            benefits,
          }}
        >
          <Row className={`${isAr ? "text-right" : "text-left"}`}>
            {data.review_type == "company" && (
              <>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="rate"
                    label={strings.submitReview.overAllRating}
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.overAllRating,
                      },
                    ]}
                  >
                    <Rate />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.pros}
                    name="pros"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.pros,
                      },
                      {
                        max: 500,
                        message: isAr
                          ? "يجب ألا يقل الايجابيات عن 3 أحرف بحد أدنى و 500 حرف كحد أقصى."
                          : "Pros should be a minimum of 3 characters and a maximum of 500 characters.",
                      },
                      {
                        min: 3,
                        message: isAr
                          ? "يجب ألا يقل الايجابيات عن 3 أحرف بحد أدنى و 500 حرف كحد أقصى."
                          : "Pros should be a minimum of 3 characters and a maximum of 500 characters.",
                      },
                    ]}
                  >
                    <Input.TextArea
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="pros"
                      placeholder={`${strings.submitReview.prosPlaceholder} ${
                        strings.getLanguage() === "arabic"
                          ? company.name_ar
                            ? company.name_ar
                            : company.name_en
                          : company.name_en
                      }`}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.cons}
                    name="cons"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.cons,
                      },
                    ]}
                  >
                    <Input.TextArea
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="cons"
                      placeholder={`${strings.submitReview.consPlaseHolder} ${
                        strings.getLanguage() === "arabic"
                          ? company.name_ar
                            ? company.name_ar
                            : company.name_en
                          : company.name_en
                      }`}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.good_Words}
                    name="good_word"
                    rules={[
                      {
                        pattern:
                          "^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$",
                        message: strings.submitReview.one_word_msg,
                      },
                      {
                        required: true,
                        message: strings.submitReview.good_Words,
                      },
                    ]}
                  >
                    <Input.TextArea
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="good_word"
                      placeholder={`${
                        strings.submitReview.goodWordsPlceholder
                      } ${
                        strings.getLanguage() === "arabic"
                          ? company.name_ar
                            ? company.name_ar
                            : company.name_en
                          : company.name_en
                      }`}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.bad_words}
                    name="bad_word"
                    rules={[
                      {
                        // pattern: "^S+$",
                        pattern:
                          "^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$",

                        message: strings.submitReview.one_word_msg,
                      },
                      {
                        required: true,
                        message: strings.submitReview.bad_words,
                      },
                      // {
                      //   max: 500,
                      //   message: isAr
                      //     ? "يجب ألا يقل السلبيات عن 3 أحرف بحد أدنى و 500 حرف كحد أقصى."
                      //     : "Cons should be a minimum of 3 characters and a maximum of 500 characters.",
                      // },
                      // {
                      //   min: 3,
                      //   message: isAr
                      //     ? "يجب ألا يقل السلبيات عن 3 أحرف بحد أدنى و 500 حرف كحد أقصى."
                      //     : "Cons should be a minimum of 3 characters and a maximum of 500 characters.",
                      // },
                    ]}
                  >
                    <Input.TextArea
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="bad_word"
                      placeholder={`${
                        strings.submitReview.badWordPlaceholder
                      } ${
                        strings.getLanguage() === "arabic"
                          ? company.name_ar
                            ? company.name_ar
                            : company.name_en
                          : company.name_en
                      }`}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {data.review_type == "culture" && (
              <>
                <CultureReview
                  culturetype={culturetype}
                  company={company}
                  setCultureType={setCultureType}
                />
              </>
            )}
            {data.review_type == "benifit" && (
              <>
                <BenefitReview
                  culturetype={culturetype}
                  company={company}
                  setCultureType={setCultureType}
                  citys={citys}
                  countrys={countrys}
                  benefits={benefits}
                />
              </>
            )}
            {data.review_type == "salary" ? (
              <>
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.basePay}
                    name="base_pay"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.basePay,
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="base_pay"
                      placeholder={strings.submitReview.basePay}
                    />
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.currency}
                    name="currency_id"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.enterCurrency,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={!currencys.length}
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.submitReview.currency}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {currencys?.map((department) => (
                        <Option value={department.id}>
                          {department?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.reviewType}
                    name="per_duration"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.perDuration,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.submitReview.reviewType}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {[
                        { name: strings.shared.year, key: "year", id: "year" },
                        {
                          name: strings.shared.month,
                          key: "month",
                          id: "month",
                        },
                        { name: strings.shared.hour, key: "hour", id: "hour" },
                      ].map((department) => (
                        <Option value={department.id}>
                          {department?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.experience_years}
                    name="experience_years"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.enterexperience_years,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={!ex.length}
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.submitReview.experience_years}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {ex?.map((department) => (
                        <Option value={department.id}>
                          {department?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.salary}
                    name="salary"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.enetrSalary,
                      },
                      {
                        max: 5,
                        message: isAr
                          ? "الحد الادني 10 الحد الاقصي 99999"
                          : "min 10 max 99999",
                      },
                      {
                        min: 2,
                        message: isAr
                          ? "الحد الادني 10 الحد الاقصي 99999"
                          : "min 10 max 99999",
                      },
                    ]}
                  >
                    <Input
                      type={"number"}
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="salary"
                      placeholder={strings.submitReview.salary}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.annual_raise}
                    name="annual_raise"
                    rules={[
                      // {
                      //   // pattern: "^[1-9][0-9]?$|^100$",
                      //   // pattern: "^(?!0?0.00$)d{1,2}.d{2}$",
                      //   pattern: "^([0-9]|[1-9][0-9]|100)$",
                      //   message: strings.submitReview.number_validation,
                      // },
                      {
                        required: true,
                        message: strings.submitReview.selectannual_raise,
                      },
                      {
                        max: 2,
                        message: isAr
                          ? "الحد الادني 1 الحد الاقصي 99"
                          : "min 1 max 99",
                      },
                      {
                        min: 1,
                        message: isAr
                          ? "الحد الادني 1 الحد الاقصي 99"
                          : "min 1 max 99",
                      },
                    ]}
                  >
                    <Input
                      // min={1}
                      // max={100}
                      type={"number"}
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="annual_raise"
                      placeholder={strings.submitReview.annual_raise}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="get_bonus"
                    label={strings.submitReview.getBouns}
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.selectgetBouns,
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        setGetBouns(e.target.value == 1 ? true : false)
                      }
                    >
                      <Radio value="1">{strings.shared.true}</Radio>
                      <Radio value="0">{strings.shared.false}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                {getBouns ? (
                  <>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label={strings.submitReview.cashBonus}
                        name="cash_bonus"
                        rules={[
                          {
                            required: true,
                            message: strings.submitReview.selectcashBonus,
                          },
                        ]}
                      >
                        <Input
                          type={"number"}
                          size="large"
                          style={{ borderRadius: 10 }}
                          className="custom-input"
                          name="cash_bonus"
                          placeholder={strings.submitReview.cashBonus}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label={strings.submitReview.cashBonusDuration}
                        name="cash_bonus_duration"
                        rules={[
                          {
                            required: true,
                            message:
                              strings.submitReview.entercashBonusDuration,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          style={{
                            textAlign: isAr ? "right" : "left",
                          }}
                          placeholder={strings.submitReview.cashBonusDuration}
                          className="custom-select"
                          size="large"
                          allowClear
                        >
                          {[
                            {
                              name: strings.shared.year,
                              key: "year",
                              id: "year",
                            },
                            {
                              name: strings.shared.month,
                              key: "month",
                              id: "month",
                            },
                          ]?.map((job) => (
                            <Option value={job.id}>{job?.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label={strings.submitReview.stockBounse}
                        name="stock_bonus"
                        rules={[
                          {
                            required: true,
                            message: strings.submitReview.enterstockBounse,
                          },
                        ]}
                      >
                        <Input
                          type={"number"}
                          size="large"
                          style={{ borderRadius: 10 }}
                          className="custom-input"
                          name="stock_bonus"
                          placeholder={strings.submitReview.stockBounse}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label={strings.submitReview.stockBounseDuration}
                        name="stock_bonus_duration"
                        rules={[
                          {
                            required: true,
                            message:
                              strings.submitReview.enterstockBounseDuration,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          style={{
                            textAlign: isAr ? "right" : "left",
                          }}
                          placeholder={
                            strings.submitReview.profitSharingDuration
                          }
                          className="custom-select"
                          size="large"
                          allowClear
                        >
                          {[
                            {
                              name: strings.shared.year,
                              key: "year",
                              id: "year",
                            },
                            {
                              name: strings.shared.month,
                              key: "month",
                              id: "month",
                            },
                          ]?.map((job) => (
                            <Option value={job.id}>{job?.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                ) : null}

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.profitSharing}
                    name="profit_sharing"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.enterprofitSharing,
                      },
                    ]}
                  >
                    <Input
                      type={"number"}
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="profit_sharing"
                      placeholder={strings.submitReview.profitSharing}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.profitSharingDuration}
                    name="profit_sharing_duration"
                    rules={[
                      {
                        required: true,
                        message:
                          strings.submitReview.enterprofitSharingDuration,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.submitReview.profitSharingDuration}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {[
                        { name: strings.shared.year, key: "year", id: "year" },
                        {
                          name: strings.shared.month,
                          key: "month",
                          id: "month",
                        },
                      ]?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.salesCommission}
                    name="sales_commission"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.selectsalesCommission,
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="sales_commission"
                      placeholder={strings.submitReview.salesCommission}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.salesCommissionDuration}
                    name="sales_commission_duration"
                    rules={[
                      {
                        required: true,
                        message:
                          strings.submitReview.entersalesCommissionDuration,
                      },
                    ]}
                  >
                    {/* <Input
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="sales_commission_duration"
                      placeholder={strings.submitReview.salesCommissionDuration}
                    /> */}
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.submitReview.profitSharingDuration}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {[
                        { name: strings.shared.year, key: "year", id: "year" },
                        {
                          name: strings.shared.month,
                          key: "month",
                          id: "month",
                        },
                      ]?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.tipsGratuities}
                    name="tips_gratuities"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.entertipsGratuities,
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="tips_gratuities"
                      placeholder={strings.submitReview.tipsGratuities}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.tipsGratuitiesDuration}
                    name="tips_gratuities_duration"
                    rules={[
                      {
                        required: true,
                        message:
                          strings.submitReview.entertipsGratuitiesDuration,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.submitReview.profitSharingDuration}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {[
                        { name: strings.shared.year, key: "year", id: "year" },
                        {
                          name: strings.shared.month,
                          key: "month",
                          id: "month",
                        },
                      ]?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.user.gender}
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: strings.user.selct_gender,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.user.gender}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {[
                        {
                          name: strings.user.female,
                          key: "female",
                          id: "female",
                        },
                        { name: strings.user.male, key: "male", id: "male" },
                      ].map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.country}
                    name="country_id"
                    rules={[
                      {
                        required: true,
                        message: strings.country,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={!countrys.length}
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.country}
                      onChange={(value) => {
                        getCitys(value);
                      }}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {countrys?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.auth.city}
                    name="city_id"
                    rules={[
                      {
                        required: true,
                        message: strings.user.validation.city,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={!citys.length}
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.auth.city}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {citys?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            ) : null}
            {data.review_type == "interview" ? (
              <>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="rate"
                    label={strings.submitReview.rate}
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.rate,
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="positive">
                        {strings.submitReview.positive}
                      </Radio>
                      <Radio value="negative">
                        {strings.submitReview.negative}
                      </Radio>
                      <Radio value="no_opinion">
                        {strings.submitReview.no_opinion}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.shared.month}
                    name="month"
                    rules={[{ required: true, message: strings.shared.month }]}
                  >
                    <Select
                      showSearch
                      loading={!month.length}
                      optionFilterProp="children"
                      style={{ textAlign: isAr ? "right" : "left" }}
                      placeholder={strings.shared.month}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {month?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.shared.year}
                    name="year"
                    rules={[{ required: true, message: strings.shared.year }]}
                  >
                    <Select
                      showSearch
                      loading={!yearOptions.length}
                      optionFilterProp="children"
                      style={{ textAlign: isAr ? "right" : "left" }}
                      placeholder={strings.shared.year}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {yearOptions?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.getBy}
                    name="get_by"
                    rules={[
                      { required: true, message: strings.submitReview.getBy },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{ textAlign: isAr ? "right" : "left" }}
                      placeholder={strings.submitReview.getBy}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {[
                        {
                          name: strings.submitReview.appliedOnline,
                          key: "applied_online",
                          id: "applied_online",
                        },
                        {
                          name: strings.submitReview.collecgeOrUn,
                          key: "college_or_university",
                          id: "college_or_university",
                        },
                        {
                          name: strings.submitReview.employeeReferral,
                          key: "employee_referral",
                          id: "employee_referral",
                        },
                        {
                          name: strings.submitReview.inPerson,
                          key: "in_person",
                          id: "in_person",
                        },
                        {
                          name: "recruiter",
                          key: "recruiter",
                          id: "recruiter",
                        },
                        {
                          name: strings.submitReview.staffingAgency,
                          key: "staffing_agency",
                          id: "staffing_agency",
                        },
                        {
                          name: strings.submitReview.others,
                          key: "other",
                          id: "other",
                        },
                      ].map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  // style={{ border: "1px solid red" }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <Form.Item
                    name="get_offer"
                    label={strings.submitReview.didYougetAnOffer}
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.didYougetAnOffer,
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="yes">{strings.submitReview?.yes}</Radio>
                      <Radio value="no">{strings.submitReview?.no}</Radio>
                      <Radio value="decline">
                        {strings.submitReview?.decline}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <p className="font-weight-bold h6 mb-3 mt-3">
                  {strings.submitReview.Whatstageswhererequired}
                </p>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.Testing}
                    name="interviewtype"
                    rules={[
                      { required: true, message: strings.submitReview.Testing },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={!interviewTypes.length}
                      optionFilterProp="children"
                      style={{ textAlign: isAr ? "right" : "left" }}
                      placeholder={strings.submitReview.Testing}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {interviewTypes?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.InterviewType}
                    name="interviewtesting"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.InterviewType,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={!interviewTest.length}
                      optionFilterProp="children"
                      style={{ textAlign: isAr ? "right" : "left" }}
                      placeholder={strings.submitReview.InterviewType}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {interviewTest?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.Others}
                    name="interviewOther"
                    rules={[
                      { required: true, message: strings.submitReview.Others },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={!others.length}
                      optionFilterProp="children"
                      style={{ textAlign: isAr ? "right" : "left" }}
                      placeholder={strings.submitReview.Others}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {others?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.difficulty}
                    name="difficulty"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.difficulty,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{ textAlign: isAr ? "right" : "left" }}
                      placeholder={strings.submitReview.difficulty}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {[
                        {
                          name: strings.submitReview.very_easy,
                          id: "very_easy",
                          key: "very_easy",
                        },
                        {
                          name: strings.submitReview.easy,
                          id: "easy",
                          key: "easy",
                        },
                        {
                          name: strings.submitReview.average,
                          id: "average",
                          key: "average",
                        },
                        {
                          name: strings.submitReview.difficult,
                          id: "difficult",
                          key: "difficult",
                        },
                        {
                          name: strings.submitReview.very_difficult,
                          id: "very_difficult",
                          key: "very_difficult",
                        },
                      ].map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.cashBonus}
                    name="cash_bonus"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.cashBonus,
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="cash_bonus"
                      placeholder={strings.submitReview.cashBonus}
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.cashBonusDuration}
                    name="cash_bonus_duration"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.cashBonusDuration,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{ textAlign: isAr ? "right" : "left" }}
                      placeholder={strings.submitReview.cashBonusDuration}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {[
                        { name: strings.shared.year, key: "year", id: "year" },
                        {
                          name: strings.shared.month,
                          key: "month",
                          id: "month",
                        },
                      ]?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.cashBonus}
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.cashBonusDuration,
                      },
                    ]}
                  >
                    <Input
                      type={"number"}
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="duration"
                      placeholder={strings.submitReview.cashBonusDuration}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.cashBonusDuration}
                    name="duration_type"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.duration_type,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.submitReview.duration_type}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {[
                        // { text: strings.shared.year, key: "year", value: "year" },
                        { name: strings.shared.day, key: "day", id: "day" },
                        { name: strings.shared.week, key: "week", id: "week" },
                        {
                          name: strings.shared.month,
                          key: "month",
                          id: "month",
                        },
                      ].map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.country}
                    name="country_id"
                    rules={[
                      {
                        required: true,
                        message: strings.country,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={!countrys.length}
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.country}
                      onChange={(value) => {
                        getCitys(value);
                      }}
                      className="custom-select"
                      size="large"
                      allowClear
                      defaultValue={133}
                    >
                      <Option value={133}>
                        {lang === "english"
                          ? "Saudi Arabia"
                          : "المملكة العربية السعودية"}
                      </Option>
                      {/* {countrys?.map((job) => (
                      ))} */}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.auth.city}
                    name="city_id"
                    rules={[
                      {
                        required: true,
                        message: strings.auth.city,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      loading={!citys.length}
                      optionFilterProp="children"
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.auth.city}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {citys?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.how_helpful}
                    name="how_helpful"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.how_helpful,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{ textAlign: isAr ? "right" : "left" }}
                      placeholder={strings.submitReview.how_helpful}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {[
                        {
                          name: strings.submitReview.very_helpful,
                          id: "very_helpful",
                          key: "very_helpful",
                        },
                        {
                          name: strings.submitReview.helpful,
                          id: "helpful",
                          key: "helpful",
                        },
                        {
                          name: strings.submitReview.not_helpful,
                          id: "not_helpful",
                          key: "not_helpful",
                        },
                        {
                          name: strings.submitReview.not_use_it,
                          id: "not_use_it",
                          key: "not_use_it",
                        },
                      ].map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label={strings.submitReview.description}
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.description,
                      },
                    ]}
                  >
                    <Input.TextArea
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="description"
                      placeholder={strings.submitReview.description}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.List name="questions">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <div
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                              width: "100%",
                              gap: "10px",
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              question
                              style={{ width: "50%" }}
                              label={strings.submitReview.question}
                              name={[name, "question"]}
                              rules={[
                                {
                                  required: true,
                                  message: strings.submitReview.question,
                                },
                              ]}
                            >
                              <Input.TextArea
                                placeholder={strings.submitReview.question}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              style={{ width: "50%" }}
                              label={strings.submitReview.answer}
                              name={[name, "answer"]}
                              rules={[
                                {
                                  required: true,
                                  message: strings.submitReview.answer,
                                },
                              ]}
                            >
                              <Input.TextArea
                                placeholder={strings.submitReview.answer}
                              />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </div>
                        ))}
                        <Form.Item>
                          <a
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p
                                style={{
                                  background: "#0089EF",
                                  width: "200px",
                                  textAlign: "center",
                                  padding: "10px 0px",
                                  borderRadius: "10px",
                                  color: "white",
                                }}
                              >
                                {strings.submitReview.selecectQuestion}
                              </p>
                            </div>
                          </a>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
                {/* <DynamicForm
                onSetQuestions={(qs) => {
                  setQuestionsDynamic(qs);
                }}
              /> */}
              </>
            ) : null}

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="accept_terms"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: strings.submitReview.required,
                  },
                ]}
              >
                <Checkbox>
                  {strings.utils.iAgreetaakkad}
                  <Link target="_blank" to="/terms" className="link_color">
                    {" "}
                    {strings.utils.termsOfUse}{" "}
                  </Link>
                  {strings.utils.reviewTermText}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <div
            className="d-flex mt-5 border-top"
            style={{ justifyContent: "space-between" }}
          >
            <div className="mt-3">
              <p className="main_color">
                {strings.newCompany.step} {step} {strings.newCompany.of} 3
              </p>
            </div>
            <div className="mt-3 d-flex">
              {step === 3 ? (
                <Button
                  primary
                  loading={submitting}
                  style={{ padding: ".8rem 4rem" }}
                  type="submit"
                >
                  {step === 3 ? strings.compareSubmit : strings.newCompany.next}
                </Button>
              ) : (
                <Button
                  primary
                  // loading={isSubmitting}
                  style={{ padding: ".8rem 4rem" }}
                  type="submit"
                >
                  {step === 3 ? strings.compareSubmit : strings.newCompany.next}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>
    )
  );
}
