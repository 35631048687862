import { Checkbox, Form, Input, Radio, Rate, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import strings from "../../../../../Localization/LNG";
import enstanse from "../../../../../utils/axios";

function CompanyReview({ companyProfile }) {
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const { Option } = Select;
  const [getBouns, setGetBouns] = useState(1);
  const [currencys, setCurrencys] = useState([]);
  const [ex, setEx] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [citys, setCitys] = useState([]);
  const getCitys = async (value) => {
    const params = {};
    if (value) {
      params.country_id = value;
    }
    const res = await enstanse.get("/cities", {
      params,
    });
    setCitys(res.data.data);
  };

  const getCountrys = async () => {
    const res = await enstanse.get("/countries");
    setCountrys(res.data.data);
  };
  const getExpe = () => {
    enstanse.get(`/agegroup/workexperience`).then((res) => {
      setEx(res.data.workexperience);
    });
  };
  const getCurrencys = async () => {
    const res = await enstanse.get("/curriencies");
    const data = res.data.data;
    setCurrencys(data);
  };
  useEffect(() => {
    getCurrencys();
    getCitys();
    getCountrys();
    getExpe();
  }, []);
  return (
    <Row>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.currency}
          name="currency_id"
          rules={[
            {
              required: true,
              message: strings.submitReview.enterCurrency,
            },
          ]}
        >
          <Select
            showSearch
            loading={!currencys.length}
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.currency}
            className="custom-select"
            size="large"
            allowClear
          >
            {currencys?.map((department) => (
              <Option value={department.id}>{department?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.reviewType}
          name="per_duration"
          rules={[
            {
              required: true,
              message: strings.submitReview.perDuration,
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.reviewType}
            className="custom-select"
            size="large"
            allowClear
          >
            {[
              { name: strings.shared.year, key: "year", id: "year" },
              {
                name: strings.shared.month,
                key: "month",
                id: "month",
              },
              { name: strings.shared.hour, key: "hour", id: "hour" },
            ].map((department) => (
              <Option value={department.id}>{department?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.experience_years}
          name="experience_years"
          rules={[
            {
              required: true,
              message: strings.submitReview.enterexperience_years,
            },
          ]}
        >
          <Select
            showSearch
            loading={!ex.length}
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.experience_years}
            className="custom-select"
            size="large"
            allowClear
          >
            {ex?.map((department) => (
              <Option value={department.id}>{department?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.salary}
          name="salary"
          rules={[
            {
              required: true,
              message: strings.submitReview.enetrSalary,
            },
            {
              max: 5,
              message: isAr
                ? "الحد الادني 10 الحد الاقصي 99999"
                : "min 10 max 99999",
            },
            {
              min: 2,
              message: isAr
                ? "الحد الادني 10 الحد الاقصي 99999"
                : "min 10 max 99999",
            },
          ]}
        >
          <Input
            type={"number"}
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="salary"
            placeholder={strings.submitReview.salary}
          />
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.annual_raise}
          name="annual_raise"
          rules={[
            // {
            //   // pattern: "^[1-9][0-9]?$|^100$",
            //   // pattern: "^(?!0?0.00$)d{1,2}.d{2}$",
            //   pattern: "^([0-9]|[1-9][0-9]|100)$",
            //   message: strings.submitReview.number_validation,
            // },
            {
              required: true,
              message: strings.submitReview.selectannual_raise,
            },
            {
              max: 2,
              message: isAr ? "الحد الادني 1 الحد الاقصي 99" : "min 1 max 99",
            },
            {
              min: 1,
              message: isAr ? "الحد الادني 1 الحد الاقصي 99" : "min 1 max 99",
            },
          ]}
        >
          <Input
            // min={1}
            // max={100}
            type={"number"}
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="annual_raise"
            placeholder={strings.submitReview.annual_raise}
          />
        </Form.Item>
      </Col>
      {/* <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          name="get_bonus"
          label={strings.submitReview.getBouns}
          rules={[
            {
              required: true,
              message: strings.submitReview.selectgetBouns,
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => setGetBouns(e.target.value == 1 ? true : false)}
          >
            <Radio value="1">{strings.shared.true}</Radio>
            <Radio value="0">{strings.shared.false}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col> */}
      {/* {getBouns ? <></> : null} */}

      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.cashBonus}
          name="cash_bonus"
          // rules={[
          //   {
          //     required: true,
          //     message: strings.submitReview.selectcashBonus,
          //   },
          // ]}
        >
          <Input
            type={"number"}
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="cash_bonus"
            placeholder={strings.submitReview.cashBonus}
          />
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.cashBonusDuration}
          name="cash_bonus_duration"
          // rules={[
          //   {
          //     required: true,
          //     message: strings.submitReview.entercashBonusDuration,
          //   },
          // ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.cashBonusDuration}
            className="custom-select"
            size="large"
            allowClear
          >
            {[
              {
                name: strings.shared.year,
                key: "year",
                id: "year",
              },
              {
                name: strings.shared.month,
                key: "month",
                id: "month",
              },
            ]?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.stockBounse}
          name="stock_bonus"
          // rules={[
          //   {
          //     required: true,
          //     message: strings.submitReview.enterstockBounse,
          //   },
          // ]}
        >
          <Input
            type={"number"}
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="stock_bonus"
            placeholder={strings.submitReview.stockBounse}
          />
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.stockBounseDuration}
          name="stock_bonus_duration"
          // rules={[
          //   {
          //     required: true,
          //     message: strings.submitReview.enterstockBounseDuration,
          //   },
          // ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.stockBounseDuration}
            className="custom-select"
            size="large"
            allowClear
          >
            {[
              {
                name: strings.shared.year,
                key: "year",
                id: "year",
              },
              {
                name: strings.shared.month,
                key: "month",
                id: "month",
              },
            ]?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.profitSharing}
          name="profit_sharing"
          // rules={[
          //   {
          //     required: true,
          //     message: strings.submitReview.enterprofitSharing,
          //   },
          // ]}
        >
          <Input
            type={"number"}
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="profit_sharing"
            placeholder={strings.submitReview.profitSharing}
          />
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.profitSharingDuration}
          name="profit_sharing_duration"
          // rules={[
          //   {
          //     required: true,
          //     message: strings.submitReview.enterprofitSharingDuration,
          //   },
          // ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.profitSharingDuration}
            className="custom-select"
            size="large"
            allowClear
          >
            {[
              { name: strings.shared.year, key: "year", id: "year" },
              {
                name: strings.shared.month,
                key: "month",
                id: "month",
              },
            ]?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.salesCommission}
          name="sales_commission"
          // rules={[
          //   {
          //     required: true,
          //     message: strings.submitReview.selectsalesCommission,
          //   },
          // ]}
        >
          <Input
            type="number"
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="sales_commission"
            placeholder={strings.submitReview.salesCommission}
          />
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.salesCommissionDuration}
          name="sales_commission_duration"
          // rules={[
          //   {
          //     required: true,
          //     message: strings.submitReview.entersalesCommissionDuration,
          //   },
          // ]}
        >
          {/* <Input
                      size="large"
                      style={{ borderRadius: 10 }}
                      className="custom-input"
                      name="sales_commission_duration"
                      placeholder={strings.submitReview.salesCommissionDuration}
                    /> */}
          <Select
            showSearch
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.salesCommissionDuration}
            className="custom-select"
            size="large"
            allowClear
          >
            {[
              { name: strings.shared.year, key: "year", id: "year" },
              {
                name: strings.shared.month,
                key: "month",
                id: "month",
              },
            ]?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.tipsGratuities}
          name="tips_gratuities"
          // rules={[
          //   {
          //     required: true,
          //     message: strings.submitReview.entertipsGratuities,
          //   },
          // ]}
        >
          <Input
            type="number"
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="tips_gratuities"
            placeholder={strings.submitReview.tipsGratuities}
          />
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.tipsGratuitiesDuration}
          name="tips_gratuities_duration"
          // rules={[
          //   {
          //     required: true,
          //     message: strings.submitReview.entertipsGratuitiesDuration,
          //   },
          // ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.tipsGratuitiesDuration}
            className="custom-select"
            size="large"
            allowClear
          >
            {[
              { name: strings.shared.year, key: "year", id: "year" },
              {
                name: strings.shared.month,
                key: "month",
                id: "month",
              },
            ]?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.user.gender}
          name="gender"
          rules={[
            {
              required: true,
              message: strings.user.selct_gender,
            },
          ]}
        >
          <Select
            // disabled
            showSearch
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.user.gender}
            className="custom-select"
            size="large"
            allowClear
          >
            {[
              {
                name: strings.user.female,
                key: "female",
                id: "female",
              },
              { name: strings.user.male, key: "male", id: "male" },
            ].map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.country}
          name="country_id"
          rules={[
            {
              required: true,
              message: strings.country,
            },
          ]}
        >
          <Select
            showSearch
            loading={!countrys.length}
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.country}
            onChange={(value) => {
              getCitys(value);
            }}
            className="custom-select"
            size="large"
            allowClear
          >
            {countrys?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.auth.city}
          name="city_id"
          rules={[
            {
              required: true,
              message: strings.user.validation.city,
            },
          ]}
        >
          <Select
            showSearch
            loading={!citys.length}
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.auth.city}
            className="custom-select"
            size="large"
            allowClear
          >
            {citys?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={12} sm={12} lg={12} xl={12}>
        <Form.Item
          name="accept_terms"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: strings.submitReview.required,
            },
          ]}
        >
          <Checkbox>
            {strings.utils.iAgreetaakkad}
            <Link target="_blank" to="/terms" className="link_color">
              {" "}
              {strings.utils.termsOfUse}{" "}
            </Link>
            {strings.utils.reviewTermText}
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
}

export default CompanyReview;
