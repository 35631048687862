import React, { useEffect, useState } from "react";
import enstanse from "../../../utils/axios";
import { Image } from "semantic-ui-react";
import LoadingLogo from "../../../Components/LoadingLogo/LoadingLogo";
import strings from "../../../Localization/LNG";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { openUnlockModal } from "../../../redux/actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-rater/lib/react-rater.css";
import Rater from "react-rater";
import logo from "../../../assets/logos/taakkad-logo.svg";
import placeholder from "../../../assets/images/Group 583@2x.jpg";
import ShowMoreText from "react-show-more-text";
import Styled from "styled-components";
import { Helmet } from "react-helmet";
import { Tooltip } from "antd";
const LogoImage = Styled.img`
  // width: 100px; 
  width: 100%;
  // height: 100%;
  padding:'0px'

`;

export default function CompanyHeader({ id, setCompanyProfile }) {
  const [data, setData] = useState(null);
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const border = isAr ? "border-left" : "border-right";
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  useEffect(() => {
    enstanse.get(`/companies/${id}/profile`).then((res) => {
      setData(res.data.data.data);
      setCompanyProfile(res.data.data.data);
    });
  }, [id]);
  if (data) {
    return (
      <React.Fragment>
        <Helmet
          title={`${strings.siteName} - ${data.name || strings.nav.overView} `}
        />
        <header
          className="image_top"
          style={{
            background: `url(${
              data?.image
                ? data.image
                : "https://cdn.discordapp.com/attachments/889412168087384084/993523418739769354/Repeat_Grid_13.png"
            })`,
            height: "18rem",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className=" overlay_company "></div>
        </header>
        <div className={`company_info ${isAr ? "ar" : "en"} `}>
          <div className="container">
            <div className="row">
              <div
                className={`col-md-2 justfyLogo company_log_col ${
                  isAr ? "" : ""
                } `}
              >
                <div className="">
                  <div className="logo company__logo border">
                    {
                      <LogoImage
                        src={data.logo ? data.logo : logo}
                        style={{ padding: "20px" }}
                      />
                    }
                  </div>
                  <div
                    style={{ marginTop: "10px" }}
                    className={`${isAr ? "alignRate" : ""}`}
                  >
                    <p
                      style={{ textAlign: `${isAr ? "right" : "left"}` }}
                      className={`${isAr ? "mr-4" : "ml-4"}`}
                    >
                      <Rater
                        rating={data?.rate}
                        interactive={false}
                        style={
                          isAr ? { fontSize: "16px" } : { fontSize: "20px" }
                        }
                      />
                      <span className="mx-2">
                        {Math.round(data?.rate * 10) / 10}{" "}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`col-md-5 mt-2 company___about_heading ${
                  isAr ? "text-right ar" : "en"
                } `}
              >
                <div className={`${isAr ? " pl-3" : " pr-3"} `}>
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="w-100">
                      <p
                        className="company__name w-100 text-uppercase main_color"
                        style={{ fontSize: "1.4rem" }}
                      >
                        {data.name}
                        {data.unlocked === 1 && (
                          <Tooltip
                            placement="top"
                            title={isAr ? "حساب موَثَّق" : "Verified account"}
                          >
                            <img
                              width={30}
                              height={30}
                              className="mr-2 ml-2"
                              src={require("../../../assets/icons/correct.svg")}
                              alt="corret"
                            />
                          </Tooltip>
                        )}
                      </p>
                    </div>
                  </div>
                  <ShowMoreText
                    lines={2}
                    more={strings.Showmore}
                    less={strings.Showless}
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    className="company__info mt-2"
                  >
                    {data.about && data.about}
                  </ShowMoreText>
                  <div className="unlock">
                    {data.unlocked === 0 && token && (
                      <div className="d-flex">
                        <div>
                          <Link
                            className="btn-link"
                            to={`/update/company/${data.id}`}
                          >
                            {strings.compnay.updateProfile}
                          </Link>
                          <FontAwesomeIcon
                            className={` ${isAr ? "mr-2" : "ml-2"} `}
                            icon={faEdit}
                          />
                        </div>
                        <div style={{ cursor: "pointer" }}>
                          <span
                            onClick={(e) => dispatch(openUnlockModal(data))}
                            className={`btn-link  ${isAr ? "mr-2" : "ml-2"} `}
                          >
                            {strings.compnay.Unlock}
                          </span>
                          <img
                            width={17}
                            height={17}
                            style={{ margin: "0  7px" }}
                            src={require("../../../assets/icons/lock.svg")}
                            alt="Unlock"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="company__more__info col-md-5">
                <div className="row h-100 align-items-center justify-content-center">
                  <div
                    className={`col-4 h-100 d-flex align-items-center justify-content-center ${border} ${
                      isAr ? "text-right" : ""
                    } `}
                  >
                    <p className={`company_showcase_text `}>
                      {strings.compnay.partings}
                      <span style={{ textAlign: "center" }}>
                        {" "}
                        {data.total_ratings}{" "}
                      </span>
                    </p>
                  </div>
                  {data?.ceo ? (
                    <div
                      className={`col-5 h-100 d-flex align-items-center justify-content-center ${
                        isAr ? "pl-0" : "pr-0"
                      }  `}
                    >
                      <div class="media">
                        <Image
                          src={
                            data?.ceo?.data?.image ||
                            "https://react.semantic-ui.com/images/wireframe/square-image.png"
                          }
                          size="mini"
                          circular
                          className={` owner-img ${isAr ? "ml-2" : "mr-1"}`}
                        />
                        <div
                          class={` ${
                            isAr ? "text-right" : ""
                          } media-body align-self-center`}
                        >
                          <p class="owner-name text_muted m-0">
                            {data?.ceo?.data?.name}
                          </p>
                          <p>
                            <Rater
                              rating={data?.ceo_rate}
                              interactive={false}
                            />
                            <span className="mx-2">
                              {Math.round(data?.ceo_rate * 10) / 10 || 0}{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <LoadingLogo />;
  }
}
