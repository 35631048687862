import React, { useEffect, useRef, useState } from "react";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import PageWraper from "../../../../Shared/PageWraper";
import CompanyHeader from "../CompanyHeader";
import styled from "styled-components";
import strings from "../../../../Localization/LNG";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaCodeBranch, FaRegBuilding, FaSlideshare } from "react-icons/fa";
import { BsCurrencyDollar, BsLightbulb } from "react-icons/bs";
import { MdLightMode } from "react-icons/md";
import Survey from "./survey/Survey";
import enstanse, { BASE_URL } from "../../../../utils/axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
const PageRateHeader = styled.div`
  /* background-image: "../../assets/images/Group\ 20.png" !important ; */
`;

function UpdatedReviews() {
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const [selectedCard, setSelectedCard] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(null);
  const userProfile = useSelector((state) => state.auth.user.data);
  const companyRef = useRef();
  const salaryRef = useRef();
  const benefitsRef = useRef();
  const cultureRef = useRef();
  const params = useParams();
  console.log({ userProfile });
  useEffect(() => {
    enstanse.get(`companies/${params.id}/profile`).then((res) => {
      setCompanyProfile(res.data.data);
    });
  }, []);
  const cardContent = [
    {
      title: isAr ? "مراجعة الشركة" : "Company review",
      key: "company",
      icon: <FaRegBuilding className="icon" />,
      body: isAr
        ? "تقديم تفاصيل عن الشركة وسلبيات وإيجابيات العمل في الشركة"
        : "Provide details about the company and the pros and cons of working in the company",
    },
    {
      title: isAr ? "مراجعة الثقافة" : "Culture review",
      key: "culture",
      icon: <MdLightMode className="icon" />,
      body: isAr
        ? "قدم تفاصيل عن ثقافة الشركة. مراجعة بيئة العمل والمسار الوظيفي وقيادة الشركة."
        : "Provide details about the company's culture. Review the work environment, career path and leadership of the company.",
    },
    {
      title: isAr ? "مراجعة الراتب" : "Salary review",
      key: "salary",
      icon: <BsCurrencyDollar className="icon" />,
      body: isAr
        ? "تقديم معلومات حول راتبك يساعد تأكد على إبراز اتجاهات الأجور والفجوات بين أصحاب العمل والصناعات والمسارات المهنية."
        : "Providing your salary information helps Takkad highlight pay trends and gaps between employers, industries and career paths.",
    },
    {
      title: isAr ? "مراجعة الفوائد" : "Benefits review",
      key: "benefits",
      icon: <FaSlideshare className="icon" />,
      body: isAr
        ? "حدد الفوائد التي تقدمها الشركة بالنقر فوق نعم أو لا أو لست متأكدًا"
        : "Select which benefits are offered by the company by clicking Yes, No, or Not Sure",
    },
  ];

  // console.log({ companyRef, salaryRef, benefitsRef, cultureRef });
  console.log({ companyRef: companyRef.current?.clientWidth });
  const [highestCard, setHighest] = useState();
  const c1 = companyRef.current?.clientHeight;
  const c2 = benefitsRef.current?.clientHeight;
  const c3 = salaryRef.current?.clientHeight;
  const c4 = cultureRef.current?.clientHeight;
  useEffect(() => {
    const highest = Math.max(c1, c3, c2, c4);
    setHighest(highest + 1);
  }, [c1, c2, c3, c4]);
  console.log({ highestCard });
  return (
    <div className="company_overview">
      <Helmet>
        <link rel="canonical" href="/write-review/:id" />
        <meta
          property="og:title"
          content={`${strings.siteName} - ${strings.nav.signup}`}
        />
        <meta property="og:url" content={`${BASE_URL}/write-review/:id`} />
        <title>
          {strings.siteName} - {strings.nav.signup}
        </title>
      </Helmet>
      <style>
        {`.cards-section{
          // position:relative;
          justify-content:center !important;
          overflow:hidden;
          // max-height:240px;
          padding: 10px 0px;

        }
        .review-card-wrapper{
          padding:15px;
          margin-bottom:20px;
          transition: max-width .2s linear .7s, transform .2s linear ${
            isSelected ? ".1s" : "0s"
          };
        }
        .active-card.company{
          // position:absolute;
          transform: translateX(${isAr ? "-150%" : "150%"});
				}
        .active-card.culture{
          // position:absolute;
          transform: translateX(${isAr ? "-50%" : "50%"});
				}
        .active-card.salary{
          // display:none;
          transform: translateX(${isAr ? "50%" : "-50%"});
        }
        .active-card.benefits{
          // display:none;
          transform: translateX(${isAr ? "150%" : "-150%"});
        }
        .not-selected-card{
          visibility:hidden;
          // transform:translateY(-300%)
        }
        .active-card .review-card{
        }
        @media only screen and (max-width: 991px) {
          .not-selected-card {
            display:none;
          }
          .active-card{
            transform:none !important;
          }
        }
				.review-card{
					min-height: 220px;
					border:2px solid #2059bb1c;
					border-radius: 10px;
					position:relative;
					overflow: hidden;
					cursor:pointer;
				}
				.card-title{
					padding: 8px 5px;
					display: flex;
					align-items: center;
					width: 100%;
					border-bottom:2px solid #2059bb1c;
				}
				.card-title h3{
					margin: 0px 7px;
					color: #0089ef;
          font-size:20px;
				}
				.icon-wrapper{
					background:#0089ef;
					padding:5px;
					border-radius:50%
				}
				.icon{
					color: white;
					font-size:20px;
				}
				.card-body{
          display:flex;
          justify-content:center;
          align-items:center;
					padding: 16px 7px;
          min-height: ${highestCard || 155}px;
				}

        // @media only screen and (max-width: 1199px) {
        //   .card-body {
        //     min-height: ${isAr ? "128px" : "147px"} !important;
        //   }
        //   .card-body h4{
        //     font-size:16px !important;
        //   }
          
        // }
        
				.card-body h4{
					text-align:center;
					font-size: 17px;
				}
				.start-btn, .cancel-btn{
					width: 130px;
					margin-bottom: 8px;
					font-size: 15px;
					font-weight: 900;
				}
				
				`}
      </style>
      <Navbar bgWhite />
      <PageWraper>
        <PageRateHeader className="rate_page bg_gray" style>
          <div
            className="content text-center pt-5 pb-5"
            style={{ height: "12rem" }}
          >
            <h3 className="font-weight-bold regiseter_title">
              {strings.submitReview.wouldLikeToContribute}
            </h3>
          </div>
        </PageRateHeader>
        <Container className="my-5">
          <Row className="cards-section">
            {cardContent.map((el) => {
              return (
                <Col
                  key={el.key}
                  className={`review-card-wrapper ${
                    selectedCard?.key === el.key ? "active-card" : ""
                  } ${
                    isSelected &&
                    selectedCard?.key !== el.key &&
                    "not-selected-card"
                  } ${el.key}`}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  // sm={isSelected && selectedCard?.key == el.key ? 12 : 6}
                  // md={isSelected && selectedCard?.key == el.key ? 12 : 6}
                  // lg={isSelected && selectedCard?.key == el.key ? 12 : 3}
                >
                  <div
                    className={`review-card d-flex flex-column align-items-center`}
                  >
                    <div className="card-title">
                      <span className="icon-wrapper">{el.icon}</span>
                      <h3>{el.title}</h3>
                    </div>
                    <div
                      className="card-body"
                      ref={
                        el.key == "company"
                          ? companyRef
                          : el.key == "culture"
                          ? cultureRef
                          : el.key == "salary"
                          ? salaryRef
                          : el.key == "benefits"
                          ? benefitsRef
                          : ""
                      }
                    >
                      <h4 className="text-muted">{el.body}</h4>
                    </div>
                    {!selectedCard ? (
                      <Button
                        className="start-btn"
                        onClick={() => {
                          setSelectedCard(el);
                          setIsSelected(true);
                        }}
                      >
                        {isAr ? "بدأ المراجعة" : "start"}
                      </Button>
                    ) : (
                      <Button
                        className="cancel-btn"
                        onClick={() => {
                          setIsSelected((prev) => !prev);
                          setSelectedCard(null);
                        }}
                      >
                        {isAr ? "الغاء" : "Cancel"}
                      </Button>
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
          <Survey
            userProfile={userProfile}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            companyProfile={companyProfile}
          />
        </Container>
      </PageWraper>
      <Footer />
    </div>
  );
}

export default UpdatedReviews;
