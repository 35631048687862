const ar = {
  siteName: "تأكد",
  homeKeywords: " تقييمات الشركات  ",
  homeTitle: "تأكد – تقييم الشركات",
  homeDescription: "تأكد – تقييمات مجانية عن الشركات و الرواتب من قبل الموظفين",

  forEmploersTitle: "تأكد لتسجيل الشركات",
  forEmploersDescription:
    "ابدأ مع تأكد لتسجيل الشركات و قم بزيادة الوعي بشركتك عن طريق الحصول علي كثير من تقييمات الموظفين",
  forEmploersKeywords: "Taakkad for employers",

  salariesKeywords: " مرتبات الشركات",
  salariesTitle: "مقارنة الراتب | تأكد",
  salariesDescription:
    "تحقق من مقارنة الراتب لمعرفة مقدار رواتب الأشخاص مثلك ، تأكد تجعل العمل أفضل وتحقق الشفافية لبيئة عملك",

  companiesKeywords: "مراجعات الشركات ",
  companiesTitle: "مراجعات الشركات | تأكد",
  companiesDescription:
    "تحقق كيف يقيم الموظفين شركاتهم و فريق العمل و بيئة العمل و التعويضات و غيرها، مراجعات الشركات",

  awardsKeywords: "جوائز العمل",
  awardsTitle: "جوائز أفضل أماكن العمل",
  awardsDescription:
    "تحقق من الشركات التي صنفتها شركة تأكد كأفضل أماكن العمل وفقًا لمراجعات الموظفين الخاصة بهم ، جوائز العمل",

  aboutusKeywords: "company reviews website",
  aboutusTitle: "Taakkad | company reviews website",
  aboutusDescription:
    "explore , compare and rate with taakkad , company reviews website that makes work environment more transparent",

  studiesKeywords: "دراسة الشركات",
  studiesTitle: " دراسة الشركات | تأكد",
  studiesDescription:
    "تحقق من دراسة الشركات التي أجاب عليها الموظفون ، مما يمنحك تقييمات موثوقة حول مكان العمل الذي تبحث عنه",

  nav: {
    companys: "الشركات",
    salaries: "الرواتب",
    studies: "الدراسات",
    awards: "الجوائز",
    signup: "انشاء حساب",
    login: "دخول",
    for_employers: "تسجيل الشركات",
    logout: "تسجيل الخروج",
    myProfile: "حسابي",
    signIn: "تسجيل الدخول",
    home: "الصفحة الرئيسية",
    overView: "نبذة عن الشركة",
  },
  pageTitles: {
    home: "الرئيسية",
  },
  home: {
    show1: "ابحث عن شركتك المثالية",
    show2: "تعويضات",
    show3: "اجعل العمل يتسم بالشفافية والمكافأة",
    featuredCompany: " أبرز الشركات",
    awardsText: "جوائز أفضل مكان للعمل",
    // topCompanys: " جوائز الشركات وجوائز الرؤساء  التنفيذيين ",
    topCompanys: " أفضل الشركات ",
    allCompanys: " كل الشركات ",
    searchForCompany: "البحث عن الشركات...",
    noResultFound: "لا يوجد نتائج",
    overallScore: "اجمالي تقييم الشركة",
    ceoRating: "تقييم الرئيس التنفيذي",
    viewCompanyPage: "عرض صفحة الشركة",
    resentRated: "الشركات المصنفة حديثا",
    viewAllCompanys: "عرض كل الشركات",
    employees: "الموظفين",
    recentStudy: "اخر الدراسات",
    vireAllStudy: "عرض كل الدراسات",
    seeAllResults: "عرض النتائج",
    companyNotAdd: "الشركة غير موجوده,تريد اضافتها؟",
    siteFeatures: {
      powerfulFeatures: "لماذا تأكد",
      one: "اكتشف الشركات",
      oneText: `اطلع على اكثر من 20000 تقييم للشركات من قبل العاملين فيها`,
      two: "قارن راتبك",
      twoText: `هل تتلقى ما تستحقه من راتب ؟ + احصل على مقارنه لراتبك الحالى مع الاف الرواتب ممن يعملون بنفس المجال`,
      three: "قارن شركتك",
      threeText: `تعرف اين تقع شركتك الحاليه فى مؤشر تأكد للشركات  الافضل بيئه للعمل`,
    },
  },

  compnay: {
    areYouCurrentEmployee: "هل انت موظف حالي او عملت بهذه الشركة من قبل؟",
    rateYourInterview: "إذا كان لديك مقابلة عمل سابقه مع هذه الشركة، ",
    clickToReview: "اضغط لمراجعة مقابلتك.",
    companyNotFoundInYourExperiences:
      "يجب اضافة الشركة في قائمة الخبرات اولاً لتتمكن من عمل مراجعة لها.",
    companyCopetitorsPlaceHolder: "لا يوجد شركات منافسة",
    ceoCopetitorsPlaceHolder: "لا يوجد رؤساء تنفيذين منافسين",
    pleaseSelectCard: "من فضلك اختر نوع المراجعة...",
    reviewTypeCompany: "مراجعة الشركة",
    reviewTypeSalary: "مراجعة الراتب",
    reviewTypeCulture: "مراجعة الثقافة",
    reviewTypeBenefits: "مراجعة المنافع",
    interviewReviews: "لا يوجد تقييمات لمقابلات العمل حتى الان",
    Benifits: "المزايا المقدمة من الشركة",
    Reviewofemployee: "مراجعة الموظف",
    mission: "الأهداف",
    vision: "رؤية",
    companies: "الشركات",
    reviewsX: "المراجعات",
    salary_review: "مراجعات الرواتب",
    searchForC: "البحث عن الشركات والتعويضات",
    companyReviewAndReating: "إكتشف أرقامنا حتي الآن    .",
    getTheWh: "وأحدث فرقًا من خلال مشاركة مراجعتك",
    companyText: `ابحث عن تقييمات واستعراضات لأكثر من 600000 شركة
    في جميع أنحاء العالم. احصل على السبق الصحفي الداخلي واكتشف ما هو حقًا
    مثل الأشخاص الذين عملوا هناك بالفعل.`,
    searchCPlaceholder: "ابحث عن الشركات",
    empolyeeParticpants: "مشاركات",
    total: "مجموع",
    partings: "مجموع التقييمات",
    socialStatistics: "الإحصاءات الاجتماعية",
    cultureScore: "نتيجة بيئة العمل",
    cultureText: "الرضا مقارنة",
    overAllCulture: "الثقافة الشاملة",
    awards: "الجوائز",
    awardEmpty: "لم يتم العثور على جوائز",
    seoScore: "نقاط الرئيس التنفيذي",
    noSeo: "لم يتم العثور على مدير تنفيذي",
    ralayRange: "توزيع الرواتب",
    updateProfile: "تحديث الملف",
    Unlock: "الغاء القفل",
    Companylogo: "شعار الشركة",
    Bestsize: "الحجم الافضل",
    qa: {
      title: " سؤال وجواب من مجهول عن شركة",
      employees: "موظفين",
      seeQuestions: "اطّلع على أسئلة حول:",
      mostResetQa: "أحدث الأسئلة والأجوبة",
      askQ: "اسأل",
      added: "مضاف",
      answer: "إجابة",
      answers: " من الإجابات",
      see: "شاهد",
      anonymousQuestion: "اطرح سؤالك بسرية",
      selectCategory: "اختر الفئة",
      typeYourQuestions: "اكتب السؤال",
      viewsAllQ: "عرض جميع الأسئلة والأجوبة حول",
      send: "ارسال",
      plaseEnterTag: "الرجاء إدخال الفئة",
      pleaseEnetrQuestion: "الرجاء إدخال السؤال",
      workingAt: "يعمل في",
      competitors: "المنافسون",
      viewAll: "مشاهدة الكل",
      questionsAbout: "أسئلة حول",
      pleaseEneterAnswer: "الرجاء إدخال الجواب",
      subitAnswer: "اجب",
      whriteYourAnswer: "اكتب إجابتك هنا",
    },
    navigation: {
      overview: "نظرة عامة",
      reviews: "المراجعات",
      salaries: "الرواتب",
      interviews: "المقابلات",
      competitors: "المنافسون",
      culture: "الثقافات",
      qa: " سؤال وجواب",
      rateCompany: "قيم الشركة",
      compareSalary: "قارن راتبك",
    },
    companyInfo: {
      companyInfo: "معلومات الشركة",
      SAR: "(ريال سعودي)",
      website: "الموقع الالكتروني",
      headquarters: "المقر",
      size: "الحجم",
      founded: "التأسسيس",
      type: "النوع",
      industry: "صناعة",
      revenue: "إيرادات",
      competitors: "المنافسين",
      headquartersCity: "مقر الشركة",
      activity: "النشاط",
      phoneNumber: "الهاتف",
      Address: "العنوان",
    },
    reviews: {
      employyedReview: "مراجعات موظفى",
      reviewWarnignTExt: "حدد هذا كمراجعة تمثيلية",
      reviewsEmpty: "لا يوجد مراجعات",
      Search: "بحث",
      searchByJobTitle: "المسمى الوظيفي للموظف",
      featuredReview: "مراجعة مميزة",
      EmployeeReviewsbyCategory: "مراجعات الموظفين حسب الفئة",
      RecentReviews: "المراجعات الأخيرة",
      SalaryReviews: "مراجعات الرواتب",
      LeadershipReviews: "مراجعات القيادة",
      CareerpathReviews: " مراجعات المسار الوظيفي ",
      EnvironmentReviews: "مراجعات البيئة ",
      InterviewReviews: "مراجعات المقابلة ",
      BenefitReviews: "مراجعة الفوائد ",
      companyReviews: "مراجعات الشركة ",
      EmployeeReviewsbyJobTitle: "مراجعات الموظف حسب المسمى الوظيفي",
      ReviewSentimentat: "مقدار الرضا عن",
    },
  },

  auth: {
    joblevel_id: "المستوى الوظيفي",
    employment_status_id: "الحالة الوظيفية",
    education_id: "التعليم",
    specilization_id: "التخصص",
    employment_status_id: "الحالة الوظيفية",
    workexperience_id: "سنوات الخبرة",
    only_positive_numbers: "ارقام موجبة فقط",
    select_job_title: "حدد المسمى الوظيفي",
    phone: "رقم الهاتف",
    name: "الأسم",
    sign_title: "قم بتسجيل الدخول إلى حساب تأكد الخاص بك",
    reg_title: "اشترك و اكتشف البيانات مجانًا",
    req_title_2: "شارك راتبك لرؤية مرتبات الرئيس التنفيذي",
    email: "البريد الالكترونى",
    password: "كلمة المرور",
    signIn: "دخول",
    forgetPassword: "نسيت كلمة المرور",
    ResetForgottenPassword: "إعادة تعيين كلمة المرور ",
    ResetPassword: "إعادة تعيين كلمة المرور",
    googleBtn: "الدخول عن طريق جوجل",
    appleAuth: "الدخول عن طريق ابل",
    appleEmailNotShared:
      "لا يمكن تسجيل الدخول ، ربما لم تقم بمشاركة بريدك الإلكتروني ",
    or: "او",
    verifyAccount: "تحقق من الحساب",
    yourEmailSuccessVerifyed: "تم التحقق من بريدك الالكتروني،",
    moreStep: `هناك خطوة أخرى لإكمال التسجيل.`,
    steps: {
      step1: "الخطوة 1: المسمى الوظيفي",
      step2: "الخطوة 2: الراتب",
      step3: "الخطوة 3: التحقق من الحساب",
    },
    company_name: "اسم الشركة",
    department: "القسم",
    tag: "التصنيف",
    jobTitle: "المسمى الوظيفي",
    city: "المدينة",
    yourSalary: "راتبك",
    bonus: "المكافاه السنوية",
    total: "المجموع",
    createPass: "انشاء كلمه مرور",
    confirmPass: "تاكيد كلمة المرور",
    enterSalary: "أدخل راتبك",
    successAuth:
      "أرسلنا بريدًا إلكترونيًا إلى بريدك الإلكتروني ، الرجاء التحقق من هذا البريد الإلكتروني",
    privateMsg: " ١٠٠٪ سري وخاص",
    validation: {
      nameRequired: "من فضلك ادخل الاسم",
      phoneRequired: "من فضلك ادخل رقم الهاتف",
      emailRequired: "من فضلك ادخل البريد الالكتروني",
      invalidEmail: " من فضلك ادخل البريد الالكتروني بصوره صحيحه",
      passwordRequired: "من فضلك ادخل كلمة المرور",
      companyRequired: "من فضلك ادخل اسم الشركة",
      departmentRequired: "من فضلك ادخل القسم",
      jobtitleRequired: "من فضلك ادخل المسمي الوظيفي",
      cityRequired: "من فضلك ادخل المدينة",
      salayRequired: "الرجاء إدخال راتبك",
      salayMaxMin: "برجاء إدخال الراتب بشكل صحيح",
      bonus: "الرجاء إدخال بونص",
      enterSalary: "ادخل الراتب",
      passwordCharcter: "يحب ان تكون كلمة المرور اكثر من 8 حروف واقل من 31 حرف",
      passwordNotSame: "كلمة المرور ليست متطابقة",
    },
  },
  user: {
    titile: "أدخل المعلومات الأساسية",
    work_ex: "خبرة في العمل",
    select_work_ex: "حدد خبرة العمل",
    job_level: "المنصب الوظيفي",
    select_job_title: "حدد مستوى الوظيفة",
    employment_Status: "الحالة الوظيفية",
    select_employment_Status: "حدد حالة التوظيف",
    city: "مدينة",
    select_city: "اختر مدينة",
    gender: "الجنس",
    selct_gender: "حدد نوع الجنس",
    age_group: "الفئة العمرية",
    select_age_group: "حدد الفئة العمرية",
    company_name: "اسم الشركة",
    select_company_name: "حدد اسم الشركة",
    education: "التعليم",
    select_education: "حدد التعليم",
    nationality: "الجنسية",
    select_nationality: "حدد الجنسية",
    female: "انثي",
    male: "ذكر",
    validation: {
      workexperience: "الرجاء إدخال خبرة العمل",
      employment_status: "الرجاء إدخال حالة التوظيف",
      education: "الرجاء إدخال التعليم",
      joblevel: "الرجاء إدخال مستوى الوظيفة",
      nationality: "الرجاء إدخال الجنسية",
      specilization: "الرجاء إدخال التخصص",

      agegroup: "الرجاء إدخال الفئة العمرية",
      gender: "الرجاء إدخال الجنس",
      company: "الرجاء إدخال الشركة",
      city: "الرجاء إدخال المدينة",
      country: "الرجاء إدخال الدولة",
    },
  },
  shared: {
    share: "المشاركة",
    learnMore: "عرض المزيد ..",
    readMore: "read more..",
    next: "التالي",
    select: "اختر",
    company: "الشركة",
    month: "شهر",
    year: "سنة",
    day: "يوم",
    week: "اسبوع",
    mustLoginFirst: "يجب عليك تسجيل الدخول أولا",
    mustloginText:
      "قبل القيام بهذا الإجراء ، يجب عليك تسجيل الدخول للقيام بذلك",
    hour: "ساعة",
    yes: "نعم",
    no: "لا",
    true: "صحيح",
    false: "خطأ",
    pros: "الايجابيات",
    cons: "سلبيات",
    cancel: "الغاء",
    edit: "تعديل",
    add: "اضف",
    employes: "عدد الموظفين",
    more: "المزيد",
  },
  footer: {
    ourCompany: "شركتنا",
    aboutus: "عن الموقع",
    press: "صحافة",
    terms: "شروط الخدمة",
    privacyPolicy: "سياسة الخصوصية",
    faq: "الاسئلة الشائعة",
    product: "المنتج",
    home: "الصفحة الرئيسية",
    interviews: "المقابلات",
    contact: "اتصل",
  },
  awards: {
    title: "جوائز تأكد لعام ٢٠٢٢",
    p: `فى كل سنه يتم تكريم الشركات  والرؤساءالتفيذيين الحائزون  على التقييم الاعلى وسوف ينم وضع شعار الجائزه فى صفحه الشركه `,
    bestCompanies: "أفضل شركة",
    showcaseAwards: "اعرض جوائز شركتك",
    showP: `من بين عشرات الآلاف من الشركات ، تم اختيارك كفائز بجوائز. اعرض الجوائز التي حصلت عليها واجعل الجميع يعرفون أن ثقافة شركتك تتميز عن البقية`,
    bestCEO: "أفضل رئيس تنفيذي",
    wantToRate: "تريد أن تقيم شركتك؟",
    wantRateShow: "ابحث عن صفحة شركتك وأرسل تقييماتك.",
    wantRate2: "يستغرق بضع دقائق فقط!",
    rateMyCompany: "قيم شركتي",
    areUEmployee: "كيف  تم تحديد  جوائز  تأكد؟",
    areUEmployeeShow: `
    سوف يتم فرز جوائز تأكد   لافضل  5 شركات  وافضل 5 رؤساء تنفيذيين بناء على تقييمات شركاؤنا من الموظفين والموظفات .`,
  },
  utils: {
    contactUs: "اتصل بنا",
    iAgreetaakkad: "أوافق على",
    termsOfUse: "الشروط والاحكام.",
    reviewTermText: `هذه المراجعة لتجربتي مع صاحب العمل الحالي أو السابق صادقة.`,
    allDepartment: "جميع الإدارات",
    allEx: "كل سنوات الخبرة",
    allCitys: "كل المدن",
    allEdQ: "المؤهل العلمي",
    allEdS: "كل التخصصات",
    getStarted: "البدء",
    age: "السن",
    uploadImage: "رفع صورة",
    dragAndDrop: "اسحب الملفات وأفلتها هنا أو",
    browse: "تصفح الملفات",
    commercial_photo: "صوره تجارية",
  },
  submitReview: {
    culturetype: "التصنيف",
    low: "منخفض",
    medium: "متوسط",
    high: "عالي",
    number_validation: "الرقم يجب ان يكون بين صفر ومائة",
    yes: "نعم",
    no: "لا",
    selectgetBouns: "برجاء ادخال اجابتك",
    decline: "مرفوض",
    Happines: "السعادة",
    rateCompany: "قيم الشركة",
    wouldLikeToContribute: "هل تود المساهمة؟",
    title1: "مراجعتك ستساعدنا , انها تستغرق دقيقة واحدة فقط !",
    title2: `كل ما تضيفه يساعد الآخرين في العثور على شركة يحبونها`,
    AddAnonymous: "أضف من مجهول ...",
    companyReview: "مراجعة الشركة",
    neutral: "محايد",
    salary: "مراجعة الراتب",
    Recruiter: "المجند",
    interViewReview: "مراجعة المقابلة",
    cultureReview: "مراجعة الثقافة",
    benefitsReviews: "مراجعات الفوائد",
    formerOrEmpoloyeer: "هل انت موظف حالي او سابق؟",
    current: "موظف حالي",
    former: "موظف سابق",
    latsYearEmpolyee: "العام الماضي في العمل",
    enterLatsYearEmpolyee: "برجاء إدخال العام الماضي في العمل",
    required: "يجب عليك الموافقه علي الشروط والاحكام",
    employerName: `اسم الشركة`,
    enteremployerName: `من فضلك ادخل اسم الشركة`,
    overAllRating: "برجاء إدخال تقييم عام",
    employmentStatus: "حالة التوظيف ",
    reviewHeadline: "عنوان المراجعة ",
    pros: "برجاء إدخال الإيجابيات ",
    prosPlaceholder: "شارك بعضًا من أفضل أسباب العمل في",
    cons: "برجاء إدخال السلبيات ",
    consPlaseHolder: "شارك بعض سلبيات العمل في",
    basePay: "الأجر الأساسي",
    currency: "العملة",
    perDuration: "من فضلك ادخل نوع التقييم",
    experience_years: "سنوات الخبرة",
    enterexperience_years: "برجاء إدخال سنوات الخبرة",
    annual_raise: "الزيادة السنوية",
    selectannual_raise: "برجاء إدخال الزيادة السنوية",
    getBouns: " يحصلون على مكافأه",
    cashBonus: "مكافأة نقدية",
    cashBonusSAR: "مكافئات (ريال سعودي)",
    selectcashBonus: "برجاء إدخال المكافأة النقدية  بشكل صحيح",
    cashBonusDuration: "نوع مدة المكافأة النقدية",
    entercashBonusDuration: "برجاء ادخال نوع مدة المكافأة النقدية",
    stockBounse: "مكافأة الأسهم",
    enterstockBounse: "برجاء ادخال مكافأة الأسهم",
    one_word_msg: "قم بادخال كلمة واحدة فقط",
    stockBounseDuration: "مدة مكافأة الأسهم",
    enterstockBounseDuration: "برجاء إدخال مشاركة الأرباح  بشكل صحيح",
    profitSharing: "مشاركة الأرباح",
    enterprofitSharing: "برجاء ادخال مشاركة الأرباح",
    profitSharingDuration: "مدة تقاسم الأرباح",
    enterprofitSharingDuration: "برجاء ادخال مدة تقاسم الأرباح",
    salesCommission: "عمولة المبيعات",
    selectsalesCommission: "برجاء إدخال عمولة المبيعات بشكل صحيح",
    salesCommissionDuration: "مدة عمولة المبيعات",
    entersalesCommissionDuration: "برجاء ادخال مدة عمولة المبيعات",
    tipsGratuities: "الإكراميات",
    entertipsGratuities: "من فضلك ادخل الاكراميات",
    tipsGratuitiesDuration: "مدة الإكراميات",
    entertipsGratuitiesDuration: "من فضلك ادخل مدة الاكراميات",
    rate: "التقييم",
    getBy: "الحصول عليها",
    appliedOnline: "تم تطبيقها عبر الإنترنت",
    collecgeOrUn: "الكلية أو الجامعة",
    employeeReferral: "إحالة موظف",
    inPerson: "شخصيا",
    staffingAgency: "وكالة التوظيف",
    others: "آخر",
    didYougetAnOffer: "هل حصلت على عرض؟",
    stages: "مراحل",
    difficulty: "صعوبة",
    very_easy: "سهل جدا",
    easy: "سهل",
    average: "متوسط",
    difficult: "صعب",
    very_difficult: "صعب جدا",
    duration: "المدة",
    duration_type: "نوع المدة",
    how_helpful: "كم هو مفيد",
    very_helpful: "مفيد جدا",
    helpful: "مفيد",
    not_helpful: "غير مساعد",
    not_use_it: "لا تستخدمه",
    description: "وصف",
    question: "السؤال",
    answer: "الاجابة",
    positive: "إيجابي",
    negative: "سلبي",
    no_opinion: "لا رأي",
    training: "التدريب",
    carearClarity: "الادراك الوظيفي",
    carearConf: "الرضا الوظيفى",
    trustInTheFeture: "الثقة بمستقبل الشركة",
    trustInleadership: "الثقة في قيادة الشركة",
    ceoRating: "تقييم الرئيس التنفيذي",
    departmentManagementPerformance: "أداء مديرين الأقسام",
    inducement: "التحفيز",
    fair_pay: "عدالة الدخل",
    team_efficiency: "كفاءة فريق العمل",
    work_creativity: "إبداع العمل",
    work_excitement: "الحماس للذهاب للعمل",
    average_working_hours: "متوسط ساعات العمل",
    out_of_office_activities: "خارج المكتب",
    trust_in_leadership: "الثقة في قيادة الشركة",
    in_job_entertainment: "الترفيه في العمل",
    happines: "السعادة",
    referral_to_others: "النصح بالالتحاق للشركة",
    general_environment: "البيئة العامة",
    company_City: "مدينة الشركة",
    employer_type: "نوع صاحب العمل",
    employee_numbers: "أرقام الموظفين",
    employee_status: "حالة الموظف",
    comment: "تعليق",
    select_witch_benefit: "حدد المزايا التي يتم تقديمها",
    un_sure: "غير متأكد",
    bad_words: "برجاء إدخال كلمة سيئة",
    good_Words: "برجاء إدخال كلمة جيدة",
    goodWordsPlceholder: "شارك ببعض الكلمات الجيدة للعمل في",
    badWordPlaceholder: "شارك ببعض الكلمات السيئة للعمل في",
    pleaseEneterjobTitle: "الرجاء إدخال المسمى الوظيفي",
    eneteryear: "الرجاء إدخال العام الماضي للموظف",
    enetrjobLevel: "الرجاء إدخال مستوى الوظيفة",
    enterDepartment: "الرجاء تحديد القسم",
    acceptTems: "الرجاء قبول شروط تأكد",
    enetrProce: "الرجاء إدخال الايجابيات",
    eneterCons: "الرجاء إدخال سلبيات",
    enterHeadline: "الرجاء إدخال عنوان المراجعة",
    eneterBasePay: "الرجاء إدخال الأجر الأساسي",
    enterCurrency: "الرجاء إدخال عملتك",
    eneterPerDuration: "الرجاء إدخال المدة",
    reviewType: "نوع التقييم",
    eneterEx: "الرجاء إدخال سنوات الخبرة",
    eneterAnuualRise: "الرجاء إدخال زيادة سنوية",
    enetergetbiunse: "الرجاء إدخال الحصول على مكافأة",
    eneterCity: "الرجاء إدخال المدينة",
    enetrSalary: "الرجاء إدخال الراتب",
    enterDescription: "الرجاء إدخال الوصف",
    enterRate: "الرجاء إدخال السعر",
    PleaseSelect: "الرجاء التحديد",
    selectDuration: "الرجاء تحديد نوع المدة",
    selectHowHelp: "الرجاء تحديد مدى فائدة",
    selecectQuestion: "الرجاء إدخال السؤال",
    difficultyRequired: "الرجاء تحديد الصعوبة",
    careerpath: "المسار المهني",
    environment: "بيئة",
    leadership: "القيادة",
    enterGoodWord: "الرجاء إدخال كلمات جيدة",
    enterBadWord: "الرجاء إدخال كلمات سيئة",
    Whatstageswhererequired: "ما هي المراحل المطلوبة؟",
    InterviewType: "نوع المقابلة",
    Testing: "اختبارات",
    Others: "الآخرين",
  },
  forEmployers: {
    forEmployers: "تسجيل الشركات",
    createFreeAcount: "إنشاء حساب ",
    unlockYourProfile: "المطالبه بملكيه صفحه شركه حاليه ",
    showT: "العلامه التجاريه لشركتك تبدأ هنا ",
    showcase2: `
    "تساعدك المراجعات والمعلومات القيمة المتوفرة على ""تأكد"" على تطوير بيئة عملك الحالية"
    `,
    enshmarkYourSelf: " قارن نفسك بالمنافسين ",
    employeeBanding: "صفحه تأكد هى بمثابه صوت شركتك على تأكد",
    employeeBrandText: `
    العلامة التجارية لصاحب العمل هي ممارسة لتحديد سمعة شركتك وتطويرها وإدارتها. تعد العلامة التجارية القوية لصاحب العمل ضرورية لتوظيف المواهب عالية الجودة والاحتفاظ بها وتساعد على تمييزك عن المنافسة. تعرف على فوائد الاستثمار في العلامة التجارية لصاحب العمل ، وكيف تختلف عن العلامة التجارية لموظفك.
    `,
    hightlight: "قم بتمييز مراجعة رائعة",
    getStartedFreeAcount: "انشاء حساب صاحب العمل مجاني",
    YourName: "الاسم",
    OfficialJobTitle: "المسمى الوظيفي الرسمي",
    WorkEmailAddress: "عنوان البريد الإلكتروني للعمل",
    WorkPhoneNumber: "رقم هاتف العمل",
    CreateAccount: "إنشاء حساب",
    term1: "بالتسجيل ، فإنك توافق على ",
    termsofUse: "تعليمات الاستخدام",
    and: " و",
    PrivacyPolicy: "سياسة الخصوصية",
    pleaseEnterName: "من فضلك أدخل إسمك",
    pleaseEnterCompany: "الرجاء إدخال اسم الشركة",
    pleaseEnterJobTitle: "الرجاء إدخال المسمى الوظيفي",
    enterEmail: "الرجاء إدخال عنوان البريد الإلكتروني",
    pleaseEnterPhone: "الرجاء إدخال رقم هاتفك",
    pleseEnterPassword: "الرجاء إدخال كلمة المرور",
    passwordLength: "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل.",
    confirmPassword: "الرجاء إدخال تأكيد كلمة المرور",
    passwordNotMatch: "كلمه السر وتاكيد كلمه السر غير متطابقان",
    EmployerProfile: "الملف الشخصي للشركة ",
    Answerreviews: "المراجعات",
    ComprehensiveAnalyticTool: "أداة تحليلية شاملة",
    Surveys: "الدراسات الاستقصائية ",
    toolsandAssets: "الأدوات والأصول",
    Setting: "الإعدادات",
  },
  salarys: {
    compareYourSalary: "قارن راتبك",
    avrageSalary: "متوسط التعويض",
    personlizedSalaray: "اطلع علي متوسط الرواتب ",
    personalizeText: `هل تتلقى  ما تستحقه من راتب ؟ + احصل على  مقارنه لراتبك  الحالى  مع الاف  الرواتب  ممن  يعملون  بنفس  المجال .`,
    anulla_rase: "الزيادة السنوية",
    salary_per_male: "رواتب الذكور",
    EmployeesBonuses: "علاوات الموظفين",
    salary_per_female: "رواتب الإناث",
    average_compensation: "متوسط الرواتب",
    median_compensation: "متوسط ​​التعويض",
    salaryAverageByDep: "متواسط الرواتب حسب القسم",
    recentSalaries: "رواتب حديثة المساهمة مع مجهول",
    salaryPlusBonus: "الراتب + المكافأة",
    averageForHight: "متوسط أعلى أجر حسب المؤهل العلمي   ",
    salaryPerLocation: "متوسط الرواتب حسب المدن",
    specialization_average: "متوسط الرواتب حسب التخصصات العلمية",
    GetBonus: "يحصلون على مكافآة",
    NotGetBonus: "لا يحصلون على مكافأه",
  },
  studies: {
    questionTitle: "هل أنت راضٍ عن مزاياك؟",
    answerdBy: "أجاب",
    inalldepartment: "في جميع الأقسام",
    howSayYes: '٪ الذين أجابوا بـ "نعم"',
    text: `77٪ من موظفي الإدارة التنفيذية اختاروا "نعم" متى
    سأل "هل أنت راض عن المزايا الخاصة بك؟" ، بينما فقط 59٪ من
    اختار موظفو القسم القانوني "نعم".`,
  },
  interviews: {
    shareYourInterview: "شارك مقابلتك",
    interviewsAt: "المقابلات في",
    experience: "الخبرة",
    getting_an_interview: "الحصول على مقابلة",
    difficulty: "صعوبة",
    average: "مدي الصعوبة",
    interviewApply: "تطبيق المقابلة",
    interviewType: "نوع المقابلة",
    interviewTests: "اختبارات المقابلة",
    AnonymousInterviewCandidate: "مرشح مقابلة مجهول",
    NoOffers: "لا عروض",
    InterviewQuestions: "اسئلة المقابلة",
    shareExTitle: "ثلاثة أسباب تجعلك تشارك تجربة المقابلة الخاصة بك!",
    qu: "هل أجريت مقابلة مؤخرا؟",
    showWhar: "شارك ما كان عليه.",
    tip1: "مساعدة الآخرين تساعدك.",
    tip2: "وضرب المثل للباحثين عن عمل يمكن أن يمنحهم الكثير من الأمل.",
    tip3: "تجربتك لا قيمة لها إلا إذا شاركتها لصالح الآخرين.",
    VeryHard: "صعب جدا",
    Hard: "صعب",
    Average: "معتدل",
    Easy: "سهل",
    veryeasy: "سهل جدا",
  },
  culture: {
    leadership: "القيادة",
    environment: "بيئة العمل",
    workCreativity: "المقدرة على الإبداع",
    JobAcceptance: "الرضى الوظيفي",
    AverageWorkingHours: "معدل ساعات العمل",
    OutOfOfficeActivities: "نشاطات خارج الدوام",
    InJobEntertainment: "الترفيه أثناء العمل",
    CareerPath: "المسار الوظيفي",
    work_excitement: "الحماس للذهاب للعمل",
    happines: "كفاءة الفريق",
    general_environment: "البيئة العامة",
  },
  search: {
    company: "شركة",
    notFounMsg: "عذرا ، لا توجد شركات مطابقة",
    adjustSearch: "اضبط البحث",
    checkSpilling: "تفقد لهجتك",
    crateAccountIfDontHaveThen: " قم بانشاء حساب اذ لم يكن لديك , ثم",
    role2: `ابحث عن اختصار أو اختصار بدلاً من الاسم الكامل ، أو العكس ("بروكتر & مغامرة" مقابل "بي & جي")`,
    secoundQ: "هل أنت موظف حالي أو سابق في",
    searchBy: `
    البحث عن طريق الشركة الأم للشركة ("سيتي" بدلاً من "سيتي بنك")
    `,
    writeReview: "اكتب مراجعة ل ،",
    role3: `وسيقوم فريقنا بإضافة الشركة إلى تأكد`,
    createCompanyProfile: "إنشاء ملف تعريف الشركة",
    giveTotaakkad: "حضور على تأكد",
    give: "اعطي",
    showcaseText: ` إذا كنت تعمل في مجال التسويق أو الموارد البشرية ، فيمكنك منح شركتك قيمة
    التواجد على تقاد من خلال إنشاء حساب صاحب عمل مجاني.
    الملايين من الناس يبحثون بالفعل عن أرباب العمل المحتملين
    هنا - والتوظيف الرائع التالي هو واحد منهم. لا تدع
    بحثهم يؤدي إلى طريق مسدود.`,
    createFreeEm: " إنشاء حساب صاحب عمل مجاني",
  },
  newCompany: {
    addRequest: "إضافة طلب لشركة جديدة",
    contactInfo: "معلومات التواصل",
    about: "عن الشركة",
    mission: "مهمات",
    telphone: "هاتف",
    address: "عنوان",
    googleMap: "خرائط جوجل",
    linkedin: "لينكدان",
    twitter: "تويتر",
    facebook: "فيسبوك",
    website: "الموقع الاكتروني",
    youtube: "يوتيوب",
    instagram: "انستجرام",
    googlePlus: "جوجل بلس",
    googlePlus: "جوجل بلس",
    CEO_Details: "تفاصيل الرئيس التنفيذي",
    CEO_Name: "اسم الرئيس التنفيذي",
    CEO_IMAGE: "صورة الرئيس التنفيذي",
    CEO_Linkedin: "لينكدان",
    step: "خطوة",
    back: "رجوع",
    of: "من",
    next: "التالي",
    submit: "ارسال",
    CEO_Photo: "صورة الرئيس التنفيذي",
  },
  profile: {
    certificates: "الشهادات",
    others: "اخري",
    help: "مساعدة",
    comment: "تعليق",
    skills: "مهارات",
    experience: "الخبرات",
    education: "التعليم",
    Myprofile: "معلومات المستخدم",
    e_mail_preference: "اشعارات البريد الإلكتروني",
    myContributions: "مساهماتي",
    support: "الدعم",
    add_certificate: "أضف شهادة",
    manageCertificates: "إدارة الشهادات",
    noCert: "لم تقم بإضافة الشهادات بعد",
    manageSkills: "إدارة المهارات",
    skillsPlaceholder: "ابدأ الكتابة لإضافة مهارات ...",
    noSkills: "لم تقم بإضافة المهارات بعد",
    addEducation: "اضف تعليم",
    manageEducation: "إدارة التعليم",
    manageEducation: "إدارة التعليم",
    manageAbout: "المعلومات الاساسية",
    manageAbout2: "تفاصيل الوظيفة",
    salary: "راتب",
    gender: " الجنس",
    save: "حفظ",
    contactUs: "اتصل بنا",
    supportPleceholder: "اكتب رسالتك الخاصه ...",
    submit: "إرسال",
    support: "الدعم",
    Type: "نوع الرسالة",
    complain: "شكوي",
    suggest: "اقتراح",
    manageEmail: "إدارة تفضيلات البريد الإلكتروني",
    COMPENSATION_NOTIFICATIONS: "إخطارات التعويض",
    salaryUpdates: "تعرف على مستحقاتك ورواتبك",
    Announcement_marketing: "اعلان وتسويق",
    ChangePassword: "تغيير كلمة المرور",
    CloseAccount: "غلق الحساب",
    addExp: "اضف الخبرة",
    at: "في",
    Basesalary: "الراتب الاساسي",
    Bonus: "علاوة",
    exEmpty: "لم تقم بإضافة الخبرات بعد",
    ManageExperience: "التجارب",
    From: "من تاريخ",
    to: "حتي تاريخ",
    certificateName: "اسم الشهادة",
    issuedDate: "تاريخ الإصدار",
    UploadImage: "تحميل صورة",
    expireddate: "منتهي الصلاحية",
    schoolName: "اسم المدرسة",
    Degree: "الدرجة العلمية",
    Fieldofstudy: "مجال الدراسة",
    From: "من",
    Date: "التاريخ",
    To: "إلى",
    issuedat: "تم إصداره في",
    exAt: "منتهية الصلاحية في",
    Grade: "درجة",
    Description: "وصف",
    eneterSchool: "الرجاء إدخال اسم المدرسة",
    eneterDegree: "الرجاء إدخال الدرجة",
    enetrFiled: "الرجاء إدخال الحقل",
    enterGrade: "الرجاء إدخال الدرجة",
    enetrStartDate: "الرجاء تحديد تاريخ البدء",
    endDate: "الرجاء تحديد تاريخ الانتهاء",
    fromRequired: "من فضلك ادحل تاريح البداية",
    certReq: "الرجاء إدخال اسم الشهادة",
    min_max:
      "برجاء إدخال إسم الشهادة بشكل صحيح( الحد الادني ٢ الحد الاقصي ٣٠ )",
    issuedRequred: "الرجاء إدخال تاريخ الإصدار",
    dateErrorValidationMsgFrom: "هذا التاريخ لم يأت بعد !",
    dateErrorValidationMsgTo: "لا يجب أن يكون أقل من تاريخ الابتداء !",
    expiredDateRequred: "الرجاء إدخال تاريخ انتهاء الصلاحية",
    enetertype: "الرجاء إدخال توع الرسالة",
    enetrMessage: "الرجاء إدخال الرسالة",
    resonType: "الرجاء إدخال نوع السبب",
    enetrReason: "الرجاء إدخال الأسباب الخاصة بك",
    noEducation: "لم تقم بإضافة التعليم بعد",
    noInterviewReviewsAdded: "لا توجد مراجعات مضافة",
    noCompanyReviewsAdded: "لم يتم إضافة مراجعات ",
    noQuestionAdded: "لا توجد أسئلة مضافة",
    questions: "أسئلة",
    views: "مشاهدات",
  },
  compare: {
    compareIn: "قارن راتبك في",
    whereUWork: "أين تعمل ؟",
    totelPerMonth: "إجمالى الراتب شهريا (ريال سعودي)",
    doUGetBonuses: `هل تحصل على مكافآت أو مشاركة في الأرباح أو عمولة مبيعات أو زيادة سنوية؟`,
    annualLeave: "الأجازة السنوية (أيام)",
    profit_sharing: "مشاركة في الربح (ريال سعودي)",
    sales_commission: "عمولة بيع (ريال سعودي)",
    annual_raise: "علاوة سنوية (%)",
    witchYearGetSalary: "في أي سنة حصلت على هذا الراتب؟",
    areUEmpolye: "هل أنت موظف حالي أو سابق؟",
    specilization: "التخصص العلمي",
    SalaryComparisonResult: "نتيجة مقارنة الراتب",
    BacktoCompanyprofile: "العودة إلى صفحة الشركة",
    erroIfLogin: "يجب عليك تسجيل الدخول لمشاهدة جميع الرسوم البيانية!",
    MainComparison: "المقارنة الرئيسية",
    CashBonusComparison: "مقارنة المكافآت النقدية",
    Annulaleave: "اجازة سنويه",
    department: "مقارنة الأقسام",
    SalesCommissionComparison: "مقارنة عمولة المبيعات",
    AnnualRaiseComparison: "مقارنة الزيادة السنوية",
    ProfitSharing: "مشاركة الأرباح",
    enetrTotalPay: "الرجاء إدخال إجمالي راتبك",
    enterAnnualLeave: "برجاء إدخال الأجازة السنوية بشكل صحيح",
    enterBouns: "الرجاء إدخال المكافأة",
    enetrJobLevel: "الرجاء تحديد مستوى الوظيفة",
    is_formerRequired: "الرجاء تحديد حالتك الحالية",
    NationalityAverage: "معدل الجنسية",
    AverageIn: "متوسط الرواتب في",
    specilizationRequired: "الرجاء إدخال التخصص",
    nationalityRequired: "الرجاء إدخال الجنسية",
    pleaseEnterYear: "الرجاء إدخال السنة",
    yearNotValid: "من فضلك ادخل تاريخ صحيح",
  },
  notifications: {
    review: "الشركة تستجيب لمراجعتك",
    question: "البعض يجيب على سؤالك",
    like_dislike: "اعجبني / لم يعجبني",
    salary: "تحديثات على آخر قيمة سوقية لي من معرفة قيمتها",
    marketing: "تسويق",
    announcement: "إعلان",
  },
  unlock: {
    title: "المطالبة بملكية صفحة شركة حاليه",
    CompanyWebsite: "موقع الشركة",
    phone: "رقم الهاتف",
    AuthorizationLetter: "خطاب تفويض",
  },
  closeAccount: {
    title: "لماذا تريد إغلاق حسابك؟",
    Typeyourreason: "اكتب السبب الخاص بك",
  },
  changPassword: {
    ChangePassword: "غير كلمة السر",
    OldPassword: "كلمة المرور القديمة",
    NewPassword: "كلمة سر جديدة",
    ConfirmNewpassword: "تاكيد كلمة المرور الجديدة",
    pleasEnterOld: "الرجاء إدخال كلمة المرور القديمة",
    enterNew: "الرجاء إدخال كلمة مرور جديدة",
    passwordConfirmation: "الرجاء إدخال تأكيد كلمة المرور",
    passwordNotMatch: "كلمة السر ليست جيدة",
  },

  noEduData: "لا يوجد بيانات تعليم",
  active: "تفعيل",
  Close: "إغلاق",
  whatDegree: "الجنس والجنسيات لموظفين شركة ",
  employeeshold: "الموظفين عقد؟",
  edText: `يوجد عدد من المتقدمين الحاصلين علي درجة البكالوريوس ٦ مرات أكثر من اولئك الحاصلين علي درجة غير معلنة ,
   ٦٣٪ من المتقدمين حاصلون علي درجة البكالوريوس و ١٤٪ منهم ليس لديهم درجة و ١٠٪ غير معلنة`,
  SAR: "ريال سعودي",
  min: "الحد الأدنى",
  max: "الحد الاقصي",
  Avg: "متوسط",
  email: "البريد",
  telephone: "الهاتف",
  GitinTouch: "ابقى على تواصل",
  noResultFound: " لآ توجد نتائج",
  bySign: "بتسجيل الدخول ، أنت توافق على",
  bySignUp: "بالتسجيل ، فإنك توافق على",
  add: "اضف ",
  compareSubmit: "قيم",
  copy_right: " تأكد - جميع الحقوق محفوظه",
  Former: "موظف سابق",
  CurrentEmployee: "موظف حالي",
  in: "في",
  SAR: "ريال سعودي",
  avg: "المتوسط",
  min: "الحد الادني",
  max: "الحد الاقصي",
  citys: "المدينة",
  entercitys: "برجاء إدخال المدينة",
  See: "",
  CompetitorsCompany: "مع أهم منافسيها",
  CompetitorsCEO: "مع أهم منافسيه",
  CulturevsCompetitors: "الثقافة مقابل المنافسين:",
  CEORatingsComparison: "مقارنة تقييمات الرؤساء التنفيذيين",
  Showmore: "أظهر المزيد",
  Showless: "عرض أقل",
  getBouns: "مكافآت الموظفين",
  selectgetBouns: "برجاء ادخال اجابتك",
  low: "منخفض",
  medium: "متوسط",
  hight: "عالي",
  NoBestCompaniesAwardsFound: "لم يتم العثور على جوائز لأفضل الشركات",
  NoBestCEOAwardsFound: "لم يتم العثور على جوائز ",
  NoStudy: "لم يتم العثور على دراسات",
  WriteAReview: "استعرض الشركات ",
  Experience: "تجربة",
  lorem: `هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي  في أي محرك بحث ستظهر العديد من المواقع الحديثة العهد في نتائج البحث. على مدى السنين ظهرت نسخ جديدة ومختلفة من نص لوريم إيبسوم، أحياناً عن طريق الصدفة، وأحياناً عن عمد كإدخال بعض العبارات الفكاهية إليها.`,
  lorem1: `حدث البيانات الأساسية لشركتك في أي وقت ، مثل موقع الشركة أو مقر الشركة أو شعار الشركة أو أهداف الشركة . `,
  lorem2: `إطلع و تفاعل مع تقييمات الموظفين ، بالإضافة إلى الترويج لأفضل المراجعات ، لإظهار بيئة عملك الحقيقية .`,
  lorem3: `إكشتف قراراتك من خلال تحليل بيانات شركتك ، على سبيل المثال: تقييم الشركة ، والتغيير في تقييم الشركة بنطاق زمني محدد ، ورضا الموظفين عن الرئيس التنفيذي للشركة ، والرواتب ، ومقابلات العمل.`,
  lorem4: `انشئ استبيان مخصص لمجموعة من الموظفين واعرض إجابات الموظفين ونتيجة هذا الاستبيان .`,
  lorem5: `يمكنك تنزيل جميع الأصول التي تم تحميلها بواسطة تأكد.`,
  lorem6: `تحكم في إعدادات شركتك ، أضف رؤساء تنفيذيين ، اختر الرئيس التنفيذي الحالي ، أضف صورًا ومقاطع فيديو لمشاركة أهم الأحداث والأنشطة لإظهار بيئة عمل الشركة.`,
  // lorem5: `5`,
  // lorem6: `6`,
  country: "الدولة",
  specialization: "التخصص",
  enterspecialization: " برجاء إدخال التخصص",
  activeAccountCloseText: `من فضلك اعلم ذلك
  إذا لم تقم بتنشيط حسابك ، فسيتم إغلاقه تلقائيًا بعد 30 يومًا من حظر الحساب`,
  pending: "معلق",
  reactiveted: "تمت إعادة تنشيط حسابك بنجاح",
  sar: "ريال",
  youEarn: "انت كسبت",
  ltta: "اقل من متوسط",
  mtta: "اكثر من متوسط",
  employee: "الموظف",
  aecpb: "متوسط ​​تعويض الموظف + المكافأة",
  msgNoQues: "عفوا انت لا تمتلك اي اسئلة",
  Experience: "الخبرة",
  Certificates: "الشهادات",
  Education: "التعليم",
  Skills: "المهارات",
  Bonus: "الاضافي",
  avrBonus: "متوسط الاضافي",
  bestSalary: "أفضل راتب",
  avrSalary: "متوسط الرواتب",
  uaauq: "لقد قمت باجابة جميع الاسئلة",
  userDashboard: "لوحة المستخدم",
  overAllCultureScore: "مجموع نقاط الثقافة",
  rateYourCompany: "قيم شركتك",
  ceoRate: "تقيم المدير التنفيذي",
  yourRankin: "ترتيبك",
  unAuth: "غير مصرح لك الوصول سيتم تسجيل خروجك",
  unAuth2: "غير مصرح لك الوصول سيتم تحويلك لصفحة تسجيل الدخول ",
  ChooseCompany: "إختر الشركة",
  noData: "لا يوجد بيانات",

  show: {
    departments: "الأقسام",
    city: "المدن",
    experience: "الخبرات",
    education: "التعليم",
    age: "الاعمار",
    gender_department: "الجنسين في الاقسام",
    gender: "النوع",
    female: "انثي",
    male: "ذكر",
  },
  NoStaticticHaveBeenFound: "لا يوجد بيانات",
};

export default ar;
