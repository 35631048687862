import React, { useState, useEffect } from "react";
import { Button, Rating } from "semantic-ui-react";
import enstanse from "../../utils/axios";
// import { Dropdown } from "semantic-ui-react";
import Dropdown from "../../Components/custom-dropdown";

import { ErrorMessage } from "formik";
// import { Form } from "react-bootstrap";
import strings from "../../Localization/LNG";
import Select from "../../Shared/CustomComponents/Select/Select";
import { Form, Row, Col, Radio, Rate } from "antd";
import "./companyRate.scss";
const { Option } = Select;

// careerpath
// environment
// leadership
const isAr = strings.getLanguage() === "arabic" ? true : false;

var numbers = new Array(10);
const TraningOtions = [];
for (var i = 0; i < numbers.length; i++) {
  TraningOtions.push({ name: i + 1, id: i + 1, key: i });
}

const CultureReview = ({ company, culturetype, setCultureType }) => {
  useEffect(() => {}, []);
  const renderInputs = () => {
    if (culturetype === "careerpath") {
      return (
        <div className="mt-4">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.training}
              name="training"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.training,
                },
              ]}
            >
              <Select
                showSearch
                loading={!TraningOtions.length}
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.submitReview.training}
                className="custom-select"
                size="large"
                allowClear
              >
                {TraningOtions?.map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="carear_clarity"
              label={strings.submitReview.carearClarity}
              rules={[
                {
                  required: true,
                  message: strings.submitReview.carearClarity,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="1">{strings.shared.yes}</Radio>
                <Radio value="0">{strings.shared.no}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="career_confidence"
              label={strings.submitReview.carearConf}
              rules={[
                {
                  required: true,
                  message: strings.submitReview.carearConf,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="1">{strings.shared.yes}</Radio>
                <Radio value="0">{strings.shared.no}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="trust_company_future"
              label={strings.submitReview.trustInTheFeture}
              rules={[
                {
                  required: true,
                  message: strings.submitReview.trustInTheFeture,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="1">{strings.shared.yes}</Radio>
                <Radio value="0">{strings.shared.no}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </div>
      );
    } else if (culturetype === "leadership") {
      return (
        <div className="mt-3">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="trust_leadership"
              label={strings.submitReview.trustInleadership}
              rules={[
                {
                  required: true,
                  message: strings.submitReview.trustInleadership,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="1">{strings.shared.yes}</Radio>
                <Radio value="0">{strings.shared.no}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="ceo_rate"
              label={strings.submitReview.ceoRating}
              rules={[
                {
                  required: true,
                  message: strings.submitReview.ceoRating,
                },
              ]}
            >
              <Rate />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.departmentManagementPerformance}
              name="managers_performance"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.departmentManagementPerformance,
                },
              ]}
            >
              <Select
                showSearch
                loading={!TraningOtions.length}
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={
                  strings.submitReview.departmentManagementPerformance
                }
                className="custom-select"
                size="large"
                allowClear
              >
                {TraningOtions?.map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.inducement}
              name="inducement"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.inducement,
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.submitReview.inducement}
                className="custom-select"
                size="large"
                allowClear
              >
                {[
                  { name: strings.shared.day, key: "day", id: "day" },
                  { name: strings.shared.week, key: "week", id: "week" },
                  { name: strings.shared.month, key: "month", id: "month" },
                  { name: strings.shared.year, key: "year", id: "year" },
                ].map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="fair_pay"
              label={strings.submitReview.fair_pay}
              rules={[
                {
                  required: true,
                  message: strings.submitReview.fair_pay,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="1">{strings.shared.yes}</Radio>
                <Radio value="0">{strings.shared.no}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.team_efficiency}
              name="team_efficiency"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.team_efficiency,
                },
              ]}
            >
              <Select
                showSearch
                loading={!TraningOtions.length}
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.submitReview.team_efficiency}
                className="custom-select"
                size="large"
                allowClear
              >
                {TraningOtions?.map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </div>
      );
    } else {
      return (
        <div className="mt-3">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.work_creativity}
              name="work_creativity"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.work_creativity,
                },
              ]}
            >
              <Select
                showSearch
                loading={!TraningOtions.length}
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.submitReview.work_creativity}
                className="custom-select"
                size="large"
                allowClear
              >
                {TraningOtions?.map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="work_excitement"
              label={strings.submitReview.work_excitement}
              rules={[
                {
                  required: true,
                  message: strings.submitReview.work_excitement,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="1">{strings.shared.yes}</Radio>
                <Radio value="0">{strings.shared.no}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.average_working_hours}
              name="average_working_hours"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.average_working_hours,
                },
              ]}
            >
              <Select
                showSearch
                loading={!TraningOtions.length}
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.submitReview.average_working_hours}
                className="custom-select"
                size="large"
                allowClear
              >
                {TraningOtions?.map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.out_of_office_activities}
              name="out_of_office_activities"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.out_of_office_activities,
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.submitReview.out_of_office_activities}
                className="custom-select"
                size="large"
                allowClear
              >
                {[
                  { name: strings.shared.day, key: "day", id: "day" },
                  { name: strings.shared.week, key: "week", id: "week" },
                  { name: strings.shared.month, key: "month", id: "month" },
                  { name: strings.shared.year, key: "year", id: "year" },
                ].map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="trust_leadership"
              label={strings.submitReview.trust_in_leadership}
              rules={[
                {
                  required: true,
                  message: strings.submitReview.trust_in_leadership,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="1">{strings.shared.yes}</Radio>
                <Radio value="0">{strings.shared.no}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.in_job_entertainment}
              name="job_entertainment"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.in_job_entertainment,
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.submitReview.in_job_entertainment}
                className="custom-select"
                size="large"
                allowClear
              >
                {[
                  { name: strings.submitReview.low, key: "low", id: "0" },
                  { name: strings.submitReview.medium, key: "medium", id: "1" },
                  { name: strings.submitReview.high, key: "high", id: "2" },
                ].map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.happines}
              name="happines"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.happines,
                },
              ]}
            >
              <Select
                showSearch
                loading={!TraningOtions.length}
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.submitReview.happines}
                className="custom-select"
                size="large"
                allowClear
              >
                {TraningOtions?.map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="referral_to_others"
              label={strings.submitReview.referral_to_others}
              rules={[
                {
                  required: true,
                  message: strings.submitReview.referral_to_others,
                },
              ]}
            >
              <Radio.Group>
                <Radio value="1">{strings.shared.yes}</Radio>
                <Radio value="0">{strings.shared.no}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={strings.submitReview.general_environment}
              name="general_environment"
              rules={[
                {
                  required: true,
                  message: strings.submitReview.general_environment,
                },
              ]}
            >
              <Select
                showSearch
                loading={!TraningOtions.length}
                optionFilterProp="children"
                style={{
                  textAlign: isAr ? "right" : "left",
                }}
                placeholder={strings.submitReview.general_environment}
                className="custom-select"
                size="large"
                allowClear
              >
                {TraningOtions?.map((job) => (
                  <Option value={job.id}>{job?.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </div>
      );
    }
  };
  const types = [
    {
      text: strings.submitReview.careerpath,
      value: "careerpath",
    },
    {
      text: strings.submitReview.environment,
      value: "environment",
    },
    {
      text: strings.submitReview.leadership,
      value: "leadership",
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <div className="typs-container">
        {types.map((t) => (
          <Button
            type="button"
            className="text-uppercase"
            style={{ borderRadius: ".6rem" }}
            content={t.text}
            onClick={() => setCultureType(t.value)}
            active={culturetype === t.value ? true : false}
          />
        ))}
      </div>
      <div style={{ width: "100%" }}>{renderInputs()}</div>
    </div>
  );
};

export default CultureReview;
