import _ from "lodash";
import React, { Component } from "react";
import { Search, Form, Label } from "semantic-ui-react";
import enstanse, { BASE_URL } from "../../../utils/axios";
import strings from "../../../Localization/LNG";
import { Link, withRouter } from "react-router-dom";
import "./search.scss";
import { Helmet } from "react-helmet";
const initialState = {
  isLoading: false,
  results: [],
  value: "",
  searchValue: "",
};

const resultRenderer = ({ title, image, is_approved, id }) => {
  return (
    <div
      dir={strings.getLanguage() == "arabic" ? "rtl" : "ltr"}
      className={`result d-flex ${is_approved ? "disabled" : ""}  `}
    >
      <div
        style={{ width: "80px", height: "70px" }}
        className="image border round p-2"
      >
        <img width={30} height={30} src={image} alt="" />
      </div>
      <div
        className={`label align-self-center mr-2 ml-2 ${
          is_approved ? "text-muted" : ""
        } `}
      >
        <span style={{ fontSize: "16px", fontWeight: "600" }}>{title}</span>
      </div>
    </div>
  );
};

class SearchExampleStandard extends Component {
  state = initialState;
  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title });
    // if (!result.is_approved) {
    if (result.id) {
      this.props.history.push(`/company/${result.id}/overview`);
    }
    this.props.setOpen(false);
  };

  handleSearchChange = ({ value }) => {
    let currentValue = value;
    this.setState({ isLoading: true, value });
    enstanse.get(`/autocomplete?key=company&q=${value}`).then((res) => {
      const result = res.data.data;

      if (currentValue == this.state.searchValue) {
        let results = [];
        _.forEach(result, (r) => {
          results.push({
            title: r.name,
            id: r.id,
            disabled: r.is_approved === 0 ? true : false,
            is_approved: r.is_approved === 0 ? true : false,
            image: r.logo
              ? r.logo
              : require("../../../assets/logos/taakkad-logo.svg"),
          });
        });

        this.setState({
          isLoading: false,
          results: results,
        });
      }
    });
  };

  handleSubmit = () => {
    if (this.state.value && this.state.value.trim() !== "") {
      this.props.history.push(`/search?q=${this.state.value}`);
    }
  };
  render() {
    console.log(strings.getLanguage() == "arabic");

    const { isLoading, value, results } = this.state;
    const lng = strings.getLanguage();
    return (
      <Form onSubmit={this.handleSubmit}>
        <Helmet>
          <link rel="canonical" href="/search" />
          <meta
            property="og:title"
            content={`${strings.siteName} - ${strings.nav.signup}`}
          />
          <meta property="og:url" content={`${BASE_URL}/search`} />
          <title>
            {strings.siteName} - {strings.nav.signup}
          </title>
        </Helmet>
        <Search
          style={{ maxWidth: "100% !important" }}
          loading={isLoading}
          onResultSelect={this.handleResultSelect}
          onSearchChange={(e, data) => {
            this.setState((e) => ({ ...e, searchValue: data.value }));
            this.handleSearchChange(data);
          }}
          resultRenderer={resultRenderer}
          noResultsMessage={
            <Link to={`/search?q=${this.state.value}`}>
              {strings.home.companyNotAdd}
            </Link>
          }
          placeholder={this.props.placeholder}
          results={results}
          input={{
            icon: "search",
            iconPosition: strings.getLanguage() ? "left" : "right",
          }}
          className={`search_bar ${lng === "arabic" ? "search_input" : ""}  ${
            lng === "arabic" ? "text-right" : "text-left"
          } `}
          value={value}
          selectFirstResult
          {...this.props}
        />
      </Form>
    );
  }
}

export default withRouter(SearchExampleStandard);
