import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import strings from "../../../../../Localization/LNG";
import enstanse from "../../../../../utils/axios";
import CompanyReview from "./CompanyReview";
import SalaryReview from "./SalaryReview";
import CultureReview from "./CultureReview";
import BenefitReview from "./BenefitsReview";
import { PropagateLoader } from "react-spinners";

function Survey({
  selectedCard,
  setSelectedCard,
  companyProfile,
  isSelected,
  setIsSelected,
  userProfile,
}) {
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  console.log({ selectedCard });
  const { token } = useSelector((state) => state.auth);
  console.log({ companyProfile });
  const [benefits, setBenefits] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getbenefits = () => {
    enstanse.get(`/benefits`).then((res) => {
      const data = res.data.data.map((el) => {
        return {
          benefit_id: el.id,
          name: el.name,
        };
      });
      console.log(data);
      setBenefits(data);
    });
  };
  useEffect(() => {
    getbenefits();
  }, []);
  const checkTypeAndUrl = (cardKey) => {
    console.log({ cardKey });
    let url;
    if (cardKey == "company") {
      url = "/company/review";
    } else if (cardKey == "salary") {
      url = "/salary/review";
    } else if (cardKey == "benefits") {
      url = "/benefit/review";
    } else if (cardKey == "careerpath") {
      url = "/careerpath/review";
    } else if (cardKey == "environment") {
      url = "/environment/review";
    } else if (cardKey == "leadership") {
      url = "/leadership/review";
    }
    return { url };
  };

  const handleSubmit = (val) => {
    console.log({ val });
    setIsSubmitting(true);
    if (selectedCard.key == "benefits") {
      // values.city_id = values.cities;
      val.benefits = val?.benefits?.map((el) => {
        return { offered: el.offered, benefit_id: el.benefit_id };
      });
    }
    let url;
    if (selectedCard.key == "culture") {
      if (val.culturetype == "careerpath") {
        url = checkTypeAndUrl("careerpath").url;
      } else if (val.culturetype == "environment") {
        url = checkTypeAndUrl("environment").url;
      } else if (val.culturetype == "leadership") {
        url = checkTypeAndUrl("leadership").url;
      }
    } else {
      url = checkTypeAndUrl(selectedCard.key).url;
    }
    enstanse
      .post(
        url,
        { ...val, employer_name: companyProfile.data.name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setIsSelected((prev) => !prev);
        setSelectedCard(null);
        toast.success(res.data.message);
        setIsSubmitting(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsSubmitting(false);
      });
  };

  return (
    <Container style={{ marginTop: "30px" }}>
      <style>
        {`
        .text-placeholder{
          color:#008FF4;
          text-align:center;
        }
        .survey-form{
          // border:1px solid red;
        }

        .survey-form-item{
          // border:1px solid red;
        }
        .ant-form-item-control-input-content{
          justify-content: start;
          display: flex;
        }
        `}
      </style>
      {/* <h3 style={{ textAlign: "center", color: "#008FF4" }}>
        {selectedCard?.key == "company"
          ? strings.submitReview.reviewTypeCompany
          : selectedCard?.key == "salary"
          ? strings.submitReview.reviewTypeCompany
          : ""}
      </h3> */}
      {
        selectedCard
          ? benefits && (
              <Form
                layout="vertical"
                className="survey-form"
                name="basic"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                // style={{ maxWidth: 600 }}
                onFinish={handleSubmit}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{
                  benefits,
                  culturetype: "careerpath",
                  get_bonus: "1",
                  gender: userProfile.gender,
                }}
              >
                {selectedCard?.key == "company" ? (
                  <CompanyReview companyProfile={companyProfile} />
                ) : selectedCard?.key == "salary" ? (
                  <SalaryReview
                    companyProfile={companyProfile}
                    userProfile={userProfile}
                  />
                ) : selectedCard?.key == "culture" ? (
                  <CultureReview companyProfile={companyProfile} />
                ) : selectedCard?.key == "benefits" ? (
                  <BenefitReview />
                ) : (
                  ""
                )}

                {isSubmitting ? (
                  <div
                    style={{
                      width: "100%",
                      height: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PropagateLoader color="#0089ef" />
                  </div>
                ) : (
                  <div className="d-flex">
                    <Form.Item className="mx-2">
                      <Button
                        style={{ borderRadius: "5px" }}
                        type="primary"
                        htmlType="submit"
                      >
                        {isAr ? "ارسال" : "Submit"}
                      </Button>
                    </Form.Item>
                    {/* <Form.Item className="mx-2">
                    <Button
                      style={{ borderRadius: "5px" }}
                      htmlType="button"
                      onClick={() => {
                        setIsSelected((prev) => !prev);
                        setSelectedCard(null);
                      }}
                    >
                      Cancel
                    </Button> 
                  </Form.Item>*/}
                  </div>
                )}
              </Form>
            )
          : selectedCard && (
              <div
                style={{
                  width: "100%",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PropagateLoader color="#0089ef" />
              </div>
            )

        // <h3 className="text-placeholder">
        //   {strings.submitReview.pleaseSelectCard}
        // </h3>
      }
    </Container>
  );
}

export default Survey;
