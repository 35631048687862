import { Checkbox, Form, Input, Radio, Rate, Space } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import strings from "../../../../../Localization/LNG";

function CompanyReview({ companyProfile }) {
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  return (
    <Row>
      <style>
        {`
        .test{
          margin-bottom: 0px;
        }
        `}
      </style>
      <Col className="survey-form-item" xs={12} sm={12} lg={12} xl={12}>
        <Form.Item
          name="rate"
          label={strings.submitReview.rate}
          rules={[
            {
              required: true,
              message: strings.submitReview.rate,
            },
          ]}
        >
          <Rate />
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={12} xl={12}>
        <Form.Item
          label={strings.submitReview.comment}
          name="comment"
          rules={[
            {
              required: true,
              message: strings.submitReview.comment,
            },
          ]}
        >
          <Input.TextArea
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="comment"
            placeholder={`${strings.submitReview.comment}`}
          />
        </Form.Item>
      </Col>
      <div className="benefits__" style={{ padding: "0px 15px" }}>
        <label
          className="d-block"
          style={{
            textAlign: "start",
            textAlign: "start",
            fontSize: "20px",
            marginBottom: "20px",
            color: "gray",
          }}
        >
          {strings.submitReview.select_witch_benefit} ...
        </label>

        <div className="mt-2 mb-3">
          <Form.List name="benefits">
            {(fields, { add, remove }) => (
              <Row>
                {fields.map(({ key, name, ...restField }) => (
                  <Col
                    xs={12}
                    sm={12}
                    lg={6}
                    xl={6}
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 20,
                      gap: "10px",
                      flexDirection: "column",
                    }}
                    align="baseline"
                    className="single-benefit-wrapper"
                  >
                    <Form.Item
                      {...restField}
                      noStyle
                      name={[name, "benefit_id"]}
                    >
                      <Input type={"hidden"} placeholder="" />
                    </Form.Item>
                    <Form.Item {...restField} noStyle name={[name, "name"]}>
                      <Input
                        disabled
                        style={{ all: "initial", pointerEvents: "none" }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="test"
                      {...restField}
                      //     label={benefits[key].name}
                      name={[name, "offered"]}
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Radio.Group>
                        <Radio.Button value="1">
                          {strings.shared.yes}
                        </Radio.Button>
                        <Radio.Button value="0">
                          {strings.shared.no}
                        </Radio.Button>
                        <Radio.Button value="2">
                          {strings.submitReview.un_sure}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            )}
          </Form.List>
        </div>
      </div>

      <Col xs={12} sm={12} lg={12} xl={12}>
        <Form.Item
          name="accept_terms"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: strings.submitReview.required,
            },
          ]}
        >
          <Checkbox>
            {strings.utils.iAgreetaakkad}
            <Link target="_blank" to="/terms" className="link_color">
              {" "}
              {strings.utils.termsOfUse}{" "}
            </Link>
            {strings.utils.reviewTermText}
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
}

export default CompanyReview;
