import React, { useState, useEffect } from "react";
import { Dropdown, Rating } from "semantic-ui-react";
import { ErrorMessage, Formik } from "formik";
import enstanse from "../../utils/axios";
import "./benifits.css";
import _ from "lodash";
import { Form, Row, Col, Radio, Rate, Input, Space, Button } from "antd";
import Select from "../../Shared/CustomComponents/Select/Select";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import strings from "../../Localization/LNG";
const isAr = strings.getLanguage() === "arabic" ? true : false;
const { Option } = Select;

const BenefitReview = ({ citys, benefits }) => {
  const [emTypes, setEmTypes] = useState([]);
  const [employeeNumers, setEmployeeNumbers] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState([]);

  const getEmployeeNumber = () => {
    enstanse.get(`/employee/numbers`).then((res) => {
      const data = res.data.data;
      setEmployeeNumbers(data);
    });
  };
  const getEmployementStatus = () => {
    enstanse.get(`/employment/status`).then((res) => {
      const data = res.data.data;

      setEmployeeStatus(data);
    });
  };
  useEffect(() => {
    getEmployeeNumber();
    getEmployementStatus();
    enstanse.get(`/types`).then((res) => {
      const data = res.data.data;
      setEmTypes(data);
    });
  }, []);
  return (
    <div style={{ width: "100%" }} className="benefiReview">
      {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item
          label={strings.auth.city}
          name="city_id"
          rules={[
            {
              required: true,
              message: strings.auth.city,
            },
          ]}
        >
          <Select
            showSearch
            loading={!citys.length}
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.auth.city}
            className="custom-select"
            size="large"
            allowClear
          >
            {citys?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col> */}
      {/* 
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item
          label={strings.submitReview.company_City}
          name="company_City"
          rules={[
            {
              required: true,
              message: strings.submitReview.company_City,
            },
          ]}
        >
          <Select
            showSearch
            loading={!citys.length}
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.company_City}
            className="custom-select"
            size="large"
            allowClear
          >
            {citys?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item
          label={strings.submitReview.employer_type}
          name="employer_type_id"
          rules={[
            {
              required: true,
              message: strings.submitReview.employer_type,
            },
          ]}
        >
          <Select
            showSearch
            loading={!emTypes.length}
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.employer_type}
            className="custom-select"
            size="large"
            allowClear
          >
            {emTypes?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item
          label={strings.submitReview.employee_numbers}
          name="employee_numbers_id"
          rules={[
            {
              required: true,
              message: strings.submitReview.employee_numbers,
            },
          ]}
        >
          <Select
            showSearch
            loading={!employeeNumers.length}
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.employee_numbers}
            className="custom-select"
            size="large"
            allowClear
          >
            {employeeNumers?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item
          label={strings.submitReview.employee_status}
          name="employment_status_id"
          rules={[
            {
              required: true,
              message: strings.submitReview.employee_status,
            },
          ]}
        >
          <Select
            showSearch
            loading={!employeeStatus.length}
            optionFilterProp="children"
            style={{
              textAlign: isAr ? "right" : "left",
            }}
            placeholder={strings.submitReview.employee_status}
            className="custom-select"
            size="large"
            allowClear
          >
            {employeeStatus?.map((job) => (
              <Option value={job.id}>{job?.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

       */}
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item
          name="rate"
          label={strings.submitReview.rate}
          rules={[
            {
              required: true,
              message: strings.submitReview.rate,
            },
          ]}
        >
          <Rate />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Form.Item
          label={strings.submitReview.comment}
          name="comment"
          rules={[
            {
              required: true,
              message: strings.submitReview.comment,
            },
          ]}
        >
          <Input.TextArea
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="comment"
            placeholder={`${strings.submitReview.comment}`}
          />
        </Form.Item>
      </Col>
      <div className="benefits__">
        <label className="d-block">
          {strings.submitReview.select_witch_benefit}
        </label>

        <div className="mt-2 mb-3">
          <Form.List name="benefits">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                    className="single-benefit-wrapper"
                  >
                    <Form.Item
                      {...restField}
                      noStyle
                      name={[name, "benefit_id"]}
                    >
                      <Input type={"hidden"} placeholder="" />
                    </Form.Item>
                    <Form.Item {...restField} noStyle name={[name, "name"]}>
                      <Input
                        disabled
                        style={{ all: "initial", pointerEvents: "none" }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      //     label={benefits[key].name}
                      name={[name, "offered"]}
                      rules={[{ required: true, message: "required" }]}
                    >
                      <Radio.Group>
                        <Radio.Button value="1">
                          {strings.shared.yes}
                        </Radio.Button>
                        <Radio.Button value="0">
                          {strings.shared.no}
                        </Radio.Button>
                        <Radio.Button value="2">
                          {strings.submitReview.un_sure}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Space>
                ))}
              </>
            )}
          </Form.List>
        </div>
      </div>
    </div>
  );
};

export default BenefitReview;
