import React, { useState } from "react";
import { Helmet } from "react-helmet";
import strings from "../../Localization/LNG";
import { toast } from "react-toastify";
import Navbar from "../../Components/Navbar/Navbar";
import qs from "qs";
import PageWraper from "../../Shared/PageWraper";
import Footer from "../../Components/Footer/Footer";
import "./companyRate.scss";
import { Card, Button, Rating, FormField } from "semantic-ui-react";
import { Formik, ErrorMessage, Field } from "formik";
import { Form } from "react-bootstrap";
import Input from "../../Shared/Input";
import { useLocation, useHistory, Link } from "react-router-dom";
import RateReviews from "./RateReview";
import enstanse, { BASE_URL } from "../../utils/axios";
import { useSelector } from "react-redux";
import styled from "styled-components";
import DynamicForm from "./dynamic-from";
import Dropdown from "../../Components/custom-dropdown";
import Step2 from "./steps/step2";
import Step1 from "./steps/step1";
import Step3 from "./steps/step3";
import * as Yup from "yup";
import _ from "lodash";
import ScrollToTop from "./scroll";
import CultureReview from "./CultureReview";
import BenefitReview from "./benifitsReview";
const PageRateHeader = styled.div`
  /* background-image: "../../assets/images/Group\ 20.png" !important ; */
`;
var month = [
  { text: "January", key: "January", value: "January" },
  { text: "February", key: "February", value: "February" },
  { text: "March", key: "March", value: "March" },
  { text: "April", key: "April", value: "April" },
  { text: "May", key: "May", value: "May" },
  { text: "April", key: "April", value: "April" },
  { text: "June", key: "June", value: "June" },
  { text: "July", key: "July", value: "July" },
  { text: "September", key: "September", value: "September" },
  { text: "October", key: "October", value: "October" },
  { text: "November", key: "November", value: "November" },
  { text: "December", key: "December", value: "December" },
];
const currentYear = new Date().getFullYear();
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const rangedValues = range(currentYear, currentYear - 50, -1);
let years = [];
rangedValues.forEach((n) => years.push({ value: n, text: n, key: n }));

const yearOptions = years;

const CompantRate = () => {
  const [educations, setEducations] = useState([]);
  const [cities, setCities] = useState([]);
  const [step, setStep] = useState(1);

  const [loadingEd, setLoadingEd] = useState(false);
  const [loadingCitys, setLoadingCitys] = useState(false);

  const [showStep1, setShowStep1] = useState(true);
  const [showStep2, setShowStep2] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [ex, setEx] = useState([]);
  const [loadingEx, setLoadingEx] = useState(false);

  const [currencys, setCurrencys] = useState([]);
  const getCurrencys = async () => {
    if (reviewType === "salary") {
      const res = await enstanse.get("/curriencies");
      const data = res.data.data;
      let dep = [];
      data.forEach((d) => {
        dep.push({ key: d.id, text: d.name, value: d.id });
      });
      setCurrencys(dep);
    }
  };
  const getExpe = () => {
    if (reviewType === "salary") {
      setLoadingEx(true);
      enstanse.get(`/agegroup/workexperience`).then((res) => {
        const data = res.data.workexperience;
        let p = [];
        _.forEach(data, (d) => {
          p.push({ key: d.id, text: d.name, value: d.id });
        });
        setEx(p);
        setLoadingEx(false);
      });
    }
  };
  const [recheak, setRecheak] = useState(null);

  /// company
  const [company, setCompany] = useState(null);
  const getCompany = () => {
    enstanse.get(`/companies/${id}/profile`).then((res) => {
      const data = res.data.data;
      setCompany(data.data);
      // console.log(data);
    });
  };

  const onSearchEdu = async (term) => {
    setLoadingEd(true);
    const res = await enstanse.get(`/autocomplete?key=education&q=${term}`);
    const data = res.data.data;
    const s = data.map((d) => {
      return { key: d.id, text: d.name, value: d.id };
    });
    setEducations(s);
    setLoadingEd(false);
  };

  const onSearchCities = async (term) => {
    setLoadingCitys(true);
    const res = await enstanse.get(`/autocomplete?key=city&q=${term}`);
    const data = res.data.data;
    const s = data.map((d) => {
      return { key: d.id, text: d.name, value: d.id };
    });
    setCities(s);
    setLoadingCitys(false);
  };

  const [specialization, setSpecialization] = useState([]);

  const getSpecialization = async (e) => {
    const res = await enstanse.get(`/qualifications/${e}/specilizations`);
    const data = res.data.data;
    const s = data.map((d) => {
      return { key: d.id, text: d.name, value: d.id };
    });
    setSpecialization(s);
  };
  const [benefits, setBenefits] = useState(null);

  const getbenefits = () => {
    enstanse.get(`/benefits`).then((res) => {
      const data = res.data.data.map((el) => {
        return {
          benefit_id: el.id,
          name: el.name,
        };
      });
      console.log(data);
      setBenefits(data);
    });
  };

  React.useEffect(() => {
    getbenefits();
    getCompany();
    onSearchEdu("");
    onSearchCities("");
  }, []);

  // interview
  const [interviewTest, setInterviewTest] = useState([]);
  const [interviewTypes, setInterviewTypes] = useState([]);
  const [loadingStages, setLoadingSTates] = useState(false);

  const [others, setOthers] = useState([]);
  const getStages = async () => {
    if (reviewType === "interview") {
      setLoadingSTates(true);
      const res = await enstanse.get("/interview/stages");
      const data = res.data.data;
      const { interview, other, testing } = data;
      let iTes = [];
      let iTypes = [];
      let iOthers = [];
      other.forEach((d) => {
        iOthers.push({ key: d.id, text: d.name, value: d.id });
      });
      interview.forEach((d) => {
        iTes.push({ key: d.id, text: d.name, value: d.id });
      });
      testing.forEach((d) => {
        iTypes.push({ key: d.id, text: d.name, value: d.id });
      });
      setInterviewTest(iTes);
      setInterviewTypes(iTypes);
      setOthers(iOthers);
      setLoadingSTates(false);
      // setStages(dep);
    }
  };
  const [countrys, setCountrys] = useState([]);
  const [citys, setCitys] = useState([]);
  const getCitys = async (country) => {
    const res = await enstanse.get("/cities", {
      params: {
        country_id: country,
      },
    });
    const data = res.data.data;
    let dep = [];
    data.forEach((d) => {
      dep.push({ key: d.id, text: d.name, value: d.id });
    });
    setCitys(dep);
  };
  const [loadingCountry, setLoadingCountry] = useState(false);

  const getCountrys = async () => {
    setLoadingCountry(true);
    const res = await enstanse.get("/countries");
    const data = res.data.data;
    setLoadingCountry(false);
    let dep = [];
    data.forEach((d) => {
      dep.push({ key: d.id, text: d.name, value: d.id });
    });
    setCountrys(dep);
  };

  const location = useLocation();
  const { type, id } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  console.log(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
  );

  const [reviewType, setReviewType] = useState(type ? type : "company");
  const [culturetype, setCultureType] = useState("careerpath");

  const { token } = useSelector((state) => state.auth);
  const history = useHistory();
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const formValidation = {
    review_type: Yup.string().required("please enter review type"),
    employer_name: Yup.string().required("please enter employer name"),
    jobtitle: Yup.string().required(strings.submitReview.pleaseEneterjobTitle),
    // last_year: Yup.string().required(strings.submitReview.eneteryear),
    joblevel: Yup.string().required(strings.submitReview.enetrjobLevel),
    department: Yup.string().required(strings.submitReview.enterDepartment),
    accept_terms: Yup.string().required(strings.submitReview.acceptTems),
  };

  if (reviewType === "company") {
    formValidation.rate = Yup.string().required(
      strings.submitReview.enetrProce
    );
    formValidation.pros = Yup.string().required(
      strings.submitReview.enetrProce
    );
    formValidation.cons = Yup.string().required(
      strings.submitReview.eneterCons
    );
    formValidation.good_word = Yup.string().required(
      strings.submitReview.enterGoodWord
    );
    formValidation.bad_word = Yup.string().required(
      strings.submitReview.enterBadWord
    );
    // formValidation.headline = Yup.string().required(
    //   strings.submitReview.enterHeadline
    // );
  }

  if (reviewType === "interview") {
    formValidation.rate = Yup.string().required(strings.submitReview.enterRate);
    formValidation.description = Yup.string().required(
      strings.submitReview.enterDescription
    );
    formValidation.get_offer = Yup.string().required(
      strings.submitReview.PleaseSelect
    );
    formValidation.duration_type = Yup.string().required(
      strings.submitReview.selectDuration
    );
    formValidation.how_helpful = Yup.string().required(
      strings.submitReview.selectHowHelp
    );
    formValidation.questions = Yup.string().required(
      strings.submitReview.selecectQuestion
    );
    formValidation.difficulty = Yup.string().required(
      strings.submitReview.difficultyRequired
    );
  }
  if (reviewType === "salary") {
    formValidation.base_pay = Yup.string().required(
      strings.submitReview.eneterBasePay
    );
    formValidation.currency_id = Yup.string().required(
      strings.submitReview.enterCurrency
    );
    formValidation.per_duration = Yup.string().required(
      strings.submitReview.eneterPerDuration
    );
    formValidation.experience_years = Yup.string().required(
      strings.submitReview.eneterEx
    );
    formValidation.salary = Yup.string().required(
      strings.submitReview.enetrSalary
    );
    formValidation.annual_raise = Yup.string().required(
      strings.submitReview.eneterAnuualRise
    );
    formValidation.get_bonus = Yup.string().required(
      strings.submitReview.enetergetbiunse
    );
    formValidation.city_id = Yup.string().required(
      strings.submitReview.eneterCity
    );
  }

  if (reviewType === "benefit") {
    formValidation.company_city = Yup.string().required(
      "please enter company city"
    );
    formValidation.employer_type_id = Yup.string().required(
      "please enter employer type"
    );
    formValidation.comment = Yup.string().required("please enter comment");
    formValidation.employment_status_id = Yup.string().required(
      "please enter employment status"
    );
  }

  const renderSalaryInputs = (
    setFieldValue,
    values,
    handleChange,
    handleBlur
  ) => {
    if (values.review_type == "salary") {
      return (
        <React.Fragment>
          <div className="form-group required">
            <label htmlFor="base_pay"> {strings.submitReview.basePay} </label>
            <Input
              name="base_pay"
              value={values.base_pay}
              id="base_pay"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={strings.submitReview.basePay}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="base_pay"
            />
          </div>
          <div className="form-group required">
            <label htmlFor="currency_id">{strings.submitReview.currency}</label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("currency_id", value.value);
              }}
              value={values.currency_id}
              placeholder={strings.submitReview.currency}
              options={currencys}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="currency_id"
            />
          </div>
          <div className="form-group required">
            <label htmlFor="per_duration">
              {strings.submitReview.perDuration}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("per_duration", value.value);
              }}
              value={values.per_duration}
              placeholder={strings.submitReview.perDuration}
              options={[
                { text: strings.shared.year, key: "year", value: "year" },
                { text: strings.shared.month, key: "month", value: "month" },
                { text: strings.shared.hour, key: "hour", value: "hour" },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="per_duration"
            />
          </div>
          <div className="form-group required">
            <label htmlFor="base_pay">
              {strings.submitReview.experience_years}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("experience_years", value.value);
              }}
              value={values.experience_years}
              placeholder={strings.submitReview.experience_years}
              options={ex}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="experience_years"
            />
          </div>
          <div className="form-group required">
            <label htmlFor="salary">{strings.submitReview.salary}</label>
            <Input
              name="salary"
              value={values.salary}
              id="salary"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={strings.submitReview.salary}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="salary"
            />
          </div>
          <div className="form-group required">
            <label htmlFor="base_pay">
              {strings.submitReview.annual_raise}
            </label>
            <Input
              name="annual_raise"
              value={values.annual_raise}
              id="annual_raise"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={strings.submitReview.annual_raise}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="annual_raise"
            />
          </div>
          <div className="form-group ">
            <label htmlFor="type" className="d-block">
              {strings.submitReview.getBouns}
            </label>
            <Form.Check
              type="radio"
              name="type"
              inline
              id="get_bonus"
              onChange={(e) => {
                setFieldValue("get_bonus", e.target.value);
              }}
              onBlur={handleBlur}
              checked={values.get_bonus == 1}
              value={1}
              label={strings.shared.true}
            />
            <Form.Check
              type="radio"
              name="type"
              inline
              id="get_bonus"
              onChange={(e) => {
                setFieldValue("get_bonus", e.target.value);
              }}
              onBlur={handleBlur}
              checked={values.get_bonus == 0}
              value={0}
              label={strings.shared.false}
            />
          </div>
          <div className="form-group">
            <label htmlFor="cash_bonus">{strings.submitReview.cashBonus}</label>
            <Input
              name="cash_bonus"
              value={values.cash_bonus}
              id="cash_bonus"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={strings.submitReview.cashBonus}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="cash_bonus"
            />
          </div>
          <div className="form-group">
            <label htmlFor="per_duration">
              {strings.submitReview.cashBonusDuration}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("cash_bonus_duration", value.value);
              }}
              value={values.cash_bonus_duration}
              placeholder={strings.submitReview.cashBonusDuration}
              options={[
                { text: strings.shared.year, key: "year", value: "year" },
                { text: strings.shared.month, key: "month", value: "month" },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="cash_bonus_duration"
            />
          </div>
          <div className="form-group">
            <label htmlFor="cash_bonus">
              {" "}
              {strings.submitReview.stockBounse}
            </label>
            <Input
              name="stock_bonus"
              value={values.stock_bonus}
              id="stock_bonus"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={strings.submitReview.stockBounse}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="stock_bonus"
            />
          </div>
          <div className="form-group">
            <label htmlFor="stock_bonus_duration">
              {strings.submitReview.stockBounseDuration}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("stock_bonus_duration", value.value);
              }}
              value={values.stock_bonus_duration}
              placeholder={strings.submitReview.stockBounseDuration}
              options={[
                { text: strings.shared.year, key: "year", value: "year" },
                { text: strings.shared.month, key: "month", value: "month" },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="stock_bonus_duration"
            />
          </div>
          <div className="form-group">
            <label htmlFor="cash_bonus">
              {strings.submitReview.profitSharing}
            </label>
            <Input
              name="profit_sharing"
              value={values.profit_sharing}
              id="profit_sharing"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={strings.submitReview.profitSharing}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="profit_sharing"
            />
          </div>
          <div className="form-group">
            <label htmlFor="per_duration">
              {strings.submitReview.profitSharingDuration}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("profit_sharing_duration", value.value);
              }}
              value={values.profit_sharing_duration}
              placeholder={strings.submitReview.profitSharingDuration}
              options={[
                { text: strings.shared.year, key: "year", value: "year" },
                { text: strings.shared.month, key: "month", value: "month" },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="profit_sharing_duration"
            />
          </div>
          <div className="form-group">
            <label htmlFor="cash_bonus">
              {strings.submitReview.salesCommission}{" "}
            </label>
            <Input
              name="sales_commission"
              value={values.sales_commission}
              id="sales_commission"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={strings.submitReview.salesCommission}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="sales_commission"
            />
          </div>
          <div className="form-group">
            <label htmlFor="sales_commission_duration">
              {strings.submitReview.salesCommissionDuration}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("sales_commission_duration", value.value);
              }}
              value={values.sales_commission_duration}
              placeholder={strings.submitReview.salesCommissionDuration}
              options={[
                { text: strings.shared.year, key: "year", value: "year" },
                { text: strings.shared.month, key: "month", value: "month" },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="sales_commission_duration"
            />
          </div>
          <div className="form-group">
            <label htmlFor="tips_gratuities">
              {" "}
              {strings.submitReview.tipsGratuities}{" "}
            </label>
            <Input
              name="tips_gratuities"
              value={values.tips_gratuities}
              id="tips_gratuities"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={strings.submitReview.tipsGratuities}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="tips_gratuities"
            />
          </div>
          <div className="form-group">
            <label htmlFor="tips_gratuities_duration">
              {strings.submitReview.tipsGratuitiesDuration}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("tips_gratuities_duration", value.value);
              }}
              value={values.tips_gratuities_duration}
              placeholder={strings.submitReview.tipsGratuitiesDuration}
              options={[
                { text: strings.shared.year, key: "year", value: "year" },
                { text: strings.shared.month, key: "month", value: "month" },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="tips_gratuities_duration"
            />
          </div>
          <div className="form-group">
            <label htmlFor="tips_gratuities_duration">
              {strings.user.gender}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("gender", value.value);
              }}
              value={values.gender}
              placeholder={strings.user.gender}
              options={[
                { text: strings.user.female, key: "female", value: "female" },
                { text: strings.user.male, key: "male", value: "male" },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="gender"
            />
          </div>
          <div className="form-group">
            <label htmlFor="city_id"> {strings.country} </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("country_id", value.value);
                getCitys(value.value);
              }}
              loading={loadingCountry}
              value={values.country_id}
              placeholder={strings.country}
              options={countrys}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="country_id"
            />
          </div>

          <div className="form-group required">
            <label htmlFor="city_id"> {strings.auth.city} </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("city_id", value.value);
              }}
              value={values.city_id}
              placeholder={strings.auth.city}
              options={citys}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="city_id"
            />
          </div>
        </React.Fragment>
      );
    }
  };

  const renderInterViewReview = (
    setFieldValue,
    values,
    handleChange,
    handleBlur
  ) => {
    if (values.review_type === "interview") {
      return (
        <React.Fragment>
          <div className="form-group required">
            <label htmlFor="base_pay" className="d-block">
              {strings.submitReview.rate}
            </label>
            <Form.Check
              type="radio"
              name="rate"
              inline
              id="positive"
              onChange={(e) => {
                setFieldValue("rate", "positive");
              }}
              onBlur={handleBlur}
              checked={values.rate === "positive"}
              value="positive"
              label={strings.submitReview.positive}
            />
            <Form.Check
              type="radio"
              name="rate"
              inline
              id="no_opinion"
              onChange={(e) => {
                setFieldValue("rate", "no_opinion");
              }}
              onBlur={handleBlur}
              checked={values.rate === "no_opinion"}
              value="no_opinion"
              label={strings.submitReview.no_opinion}
            />

            <Form.Check
              type="radio"
              name="rate"
              inline
              id="negative"
              onChange={(e) => {
                setFieldValue("rate", "negative");
              }}
              onBlur={handleBlur}
              checked={values.rate === "negative"}
              value="negative"
              label={strings.submitReview.negative}
            />

            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="rate"
            />
          </div>
          <div className="form-group">
            <label htmlFor="currency_id"> {strings.shared.month} </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("month", value.value);
              }}
              value={values.month}
              placeholder={strings.shared.month}
              options={month}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="month"
            />
          </div>
          <div className="form-group">
            <label htmlFor="year">{strings.shared.year}</label>
            <Dropdown
              placeholder="Select"
              className="select_form"
              fluid
              search
              selection
              value={values.year}
              onChange={(e, value) => {
                setFieldValue("year", value.value);
              }}
              options={yearOptions}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="month"
            />
          </div>
          <div className="form-group">
            <label htmlFor="per_duration"> {strings.submitReview.getBy} </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("get_by", value.value);
              }}
              value={values.get_by}
              placeholder={strings.submitReview.getBy}
              options={[
                {
                  text: strings.submitReview.appliedOnline,
                  key: "applied_online",
                  value: "applied_online",
                },
                {
                  text: strings.submitReview.collecgeOrUn,
                  key: "college_or_university",
                  value: "college_or_university",
                },
                {
                  text: strings.submitReview.employeeReferral,
                  key: "employee_referral",
                  value: "employee_referral",
                },
                {
                  text: strings.submitReview.inPerson,
                  key: "in_person",
                  value: "in_person",
                },
                { text: "recruiter", key: "recruiter", value: "recruiter" },
                {
                  text: strings.submitReview.staffingAgency,
                  key: "staffing_agency",
                  value: "staffing_agency",
                },
                {
                  text: strings.submitReview.others,
                  key: "other",
                  value: "other",
                },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="per_duration"
            />
          </div>
          <div className="form-group required">
            <label htmlFor="get_offer" className="d-block">
              {strings.submitReview.didYougetAnOffer}
            </label>
            {["yes", "decline", "no"].map((key) => (
              <Form.Check
                type="radio"
                name="get_offer"
                inline
                id={key}
                onChange={(e) => {
                  setFieldValue("get_offer", e.target.value);
                }}
                onBlur={handleBlur}
                checked={values.get_offer == key}
                value={key}
                label={key}
              />
            ))}
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="get_offer"
            />
          </div>
          <div className="stages mt-2">
            <p className="font-weight-bold h6 mb-3 mt-3">
              {strings.submitReview.Whatstageswhererequired}
            </p>
            <div className="form-group required">
              <label htmlFor="stages">
                {" "}
                {strings.submitReview.InterviewType}
              </label>
              <Dropdown
                className="select_form"
                search
                fluid
                selection
                onChange={(e, value) => {
                  setFieldValue("interviewtype", value.value);
                }}
                value={values.interviewtype}
                placeholder={strings.submitReview.InterviewType}
                options={interviewTypes}
                loading={loadingStages}
              />
              <ErrorMessage
                className={`text-danger`}
                component="span"
                name="stages"
              />
            </div>
            <div className="form-group required">
              <label htmlFor="stages"> {strings.submitReview.Testing} </label>
              <Dropdown
                className="select_form"
                search
                fluid
                selection
                onChange={(e, value) => {
                  setFieldValue("interviewtesting", value.value);
                }}
                value={values.interviewtesting}
                placeholder={strings.submitReview.Testing}
                options={interviewTest}
                loading={loadingStages}
              />
              <ErrorMessage
                className={`text-danger`}
                component="span"
                name="stages"
              />
            </div>
            <div className="form-group">
              <label htmlFor="stages"> {strings.submitReview.Others} </label>
              <Dropdown
                className="select_form"
                search
                fluid
                selection
                onChange={(e, value) => {
                  setFieldValue("interviewOther", value.value);
                }}
                value={values.interviewother}
                placeholder={strings.submitReview.Others}
                options={others}
                loading={loadingStages}
              />
            </div>
          </div>
          {/* <div className="form-group">
            <label htmlFor="stages"> {strings.submitReview.stages} </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("stages", value.value);
              }}
              value={values.stages}
              placeholder={strings.submitReview.stages}
              multiple
              options={stages}
              loading={loadingStages}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="month"
            />
          </div> */}
          <div className="form-group required">
            <label htmlFor="difficulty" className="d-block">
              {strings.submitReview.difficulty}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("difficulty", value.value);
              }}
              value={values.difficulty}
              placeholder={strings.submitReview.difficulty}
              options={[
                {
                  text: strings.submitReview.very_easy,
                  value: "very_easy",
                  key: "very_easy",
                },
                { text: strings.submitReview.easy, value: "easy", key: "easy" },
                {
                  text: strings.submitReview.average,
                  value: "average",
                  key: "average",
                },
                {
                  text: strings.submitReview.difficult,
                  value: "difficult",
                  key: "difficult",
                },
                {
                  text: strings.submitReview.easy.very_difficult,
                  value: "very_difficult",
                  key: "very_difficult",
                },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="difficulty"
            />
          </div>
          <div className="form-group">
            <label htmlFor="cash_bonus">
              {" "}
              {strings.submitReview.cashBonus}{" "}
            </label>
            <Input
              name="cash_bonus"
              value={values.cash_bonus}
              id="cash_bonus"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={strings.submitReview.cashBonus}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="cash_bonus"
            />
          </div>
          <div className="form-group">
            <label htmlFor="per_duration">
              {strings.submitReview.cashBonusDuration}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("cash_bonus_duration", value.value);
              }}
              value={values.cash_bonus_duration}
              placeholder={strings.submitReview.cashBonusDuration}
              options={[
                { text: strings.shared.year, key: "year", value: "year" },
                { text: strings.shared.month, key: "month", value: "month" },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="cash_bonus_duration"
            />
          </div>
          <div className="form-group">
            <label htmlFor="duration">
              {" "}
              {strings.submitReview.cashBonusDuration}{" "}
            </label>
            <Input
              name="duration"
              value={values.duration}
              id="duration"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={strings.submitReview.cashBonusDuration}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="cash_bonus"
            />
          </div>
          <div className="form-group required">
            <label htmlFor="duration_type">
              {strings.submitReview.duration_type}{" "}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("duration_type", value.value);
              }}
              value={values.duration_type}
              placeholder={strings.submitReview.duration_type}
              options={[
                // { text: strings.shared.year, key: "year", value: "year" },
                { text: strings.shared.day, key: "day", value: "day" },
                { text: strings.shared.week, key: "week", value: "week" },
                { text: strings.shared.month, key: "month", value: "month" },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="duration_type"
            />
          </div>

          <div className="form-group">
            <label htmlFor="city_id"> {strings.country} </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("country_id", value.value);
                getCitys(value.value);
              }}
              loading={loadingCountry}
              value={values.country_id}
              placeholder={strings.country}
              options={countrys}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="country_id"
            />
          </div>

          <div className="form-group">
            <label htmlFor="city_id"> {strings.auth.city} </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("city_id", value.value);
              }}
              value={values.city_id}
              placeholder={strings.auth.city}
              options={citys}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="city_id"
            />
          </div>
          <div className="form-group required">
            <label htmlFor="difficulty" className="d-block">
              {strings.submitReview.how_helpful}
            </label>
            <Dropdown
              className="select_form"
              search
              fluid
              selection
              onChange={(e, value) => {
                setFieldValue("how_helpful", value.value);
              }}
              value={values.how_helpful}
              placeholder="how helpful"
              options={[
                {
                  text: strings.submitReview.very_helpful,
                  value: "very_helpful",
                  key: "very_helpful",
                },
                {
                  text: strings.submitReview.helpful,
                  value: "helpful",
                  key: "helpful",
                },
                {
                  text: strings.submitReview.not_helpful,
                  value: "not_helpful",
                  key: "not_helpful",
                },
                {
                  text: strings.submitReview.not_use_it,
                  value: "not_use_it",
                  key: "not_use_it",
                },
              ]}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="how_helpful"
            />
          </div>
          <div className="form-group required">
            <label htmlFor="difficulty" className="d-block">
              {strings.submitReview.description}
            </label>
            <textarea
              className="select_form"
              cols="30"
              rows="3"
              id="description"
              onChange={handleChange}
              name="description"
              value={values.description}
              onBlur={handleBlur}
              placeholder={strings.submitReview.description}
            />
            <ErrorMessage
              className={`text-danger`}
              component="span"
              name="description"
            />
          </div>
          <DynamicForm
            onSetQuestions={(qs) => {
              setFieldValue("questions", qs);
            }}
          />
        </React.Fragment>
      );
    }
  };

  React.useEffect(() => {
    // getEmploymentStatus();
    // getDepartment();
    getExpe();
    // getJobLevel();
    getStages();
    // getJobs();
    getCurrencys();
    getCountrys();
    getCitys();
  }, [reviewType]);
  const validation = Yup.object().shape(formValidation);
  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }
  const [data, setData] = useState({});
  // React.useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, [step]);

  return (
    company && (
      <div className="registration">
        <Helmet>
          <link rel="canonical" href="/rateCompany" />
          <meta
            property="og:title"
            content={`${strings.siteName} - ${
              strings.submitReview.rateCompany
            } ${company ? company.name : ""}`}
          />
          <meta property="og:url" content={`${BASE_URL}/rateCompany`} />
          <title>
            {strings.siteName} -{" "}
            {`${strings.submitReview.rateCompany} ${
              company ? company.name : ""
            }`}
          </title>
        </Helmet>
        <Navbar bgWhite />
        <PageWraper style={{ background: "#F6F6F6" }}>
          <PageRateHeader className="rate_page bg_gray">
            <div
              className="content text-center pt-5 pb-5"
              style={{ height: "12rem" }}
            >
              <h3 className="font-weight-bold regiseter_title">
                {showStep2
                  ? `${strings.submitReview.rateCompany} ${
                      company ? company.name : ""
                    }`
                  : strings.submitReview.wouldLikeToContribute}
              </h3>
            </div>
          </PageRateHeader>
          <div className="container">
            <Card
              style={{ transform: "translateY(-60px)" }}
              className="w-100 h-100 pb-5"
            >
              <Card.Content>
                <p
                  style={{ fontSize: "1.4rem" }}
                  className="text-center main_color"
                >
                  {showStep2
                    ? strings.submitReview.title2
                    : strings.submitReview.title1}
                </p>
                <div className="mt-3">
                  <ScrollToTop gotoTop={step} />

                  {step === 1 && (
                    <Step1
                      data={data}
                      onNext={(value) => {
                        setData(value);
                        setStep(2);
                      }}
                      step={step}
                      company={company && company}
                    />
                  )}
                  {step === 2 && (
                    <Step2
                      step={step}
                      onNext={(values) => {
                        setData({ ...data, ...values });
                        setStep(3);
                      }}
                    />
                  )}
                  {step === 3 && (
                    <Step3
                      company={company && company}
                      step={step}
                      data={data}
                      benefits={benefits}
                    />
                  )}
                </div>
              </Card.Content>
            </Card>
          </div>
        </PageWraper>
        <Footer />
      </div>
    )
  );
};

export default CompantRate;
