import React, { useState } from "react";
import logo from "../../assets/logos/taakkad-logo.svg";
import "./Navbar.scss";
import { useSelector, useDispatch } from "react-redux";
import strings from "../../Localization/LNG";
import withDirection from "react-with-direction";
import { Button, Dropdown } from "semantic-ui-react";
import { Flag } from "semantic-ui-react";
import {
  NavLink,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import { Logout } from "../../redux/actions/authAction";
import { Avatar } from "grommet";
import { Icon } from "semantic-ui-react";
import SearchModal from "../../Components/searchCompanyModal";
// import { Button, Header, Icon, Modal } from "semantic-ui-react";

function Navbar({ scroll, bgWhite }) {
  const [isTop, setIsTop] = useState(true);
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const routerMatch = useRouteMatch();
  const isTransform = routerMatch.path === "/company/:id" ? true : false;
  // console.log(routerMatch.path);
  const {
    lng: { selected },
  } = useSelector((state) => state);
  const lng = strings.getLanguage();
  const onChangeLang = () => {
    if (lng === "english") {
      window.location.reload();
      localStorage.setItem("lng", "arabic");
    } else {
      window.location.reload();
      localStorage.setItem("lng", "english");
    }
  };

  document.addEventListener("scroll", () => {
    const amount = scroll ? scroll : 50;
    const isWindowTop = window.scrollY < amount;
    setIsTop(isWindowTop);
  });

  const { token, user } = useSelector((state) => state.auth);
  const renderIsAuth = () => {
    if (!token) {
      return (
        <React.Fragment>
          <li className="nav-item">
            <Button
              className={lng === "english" ? "text_ar" : ""}
              onClick={() => history.push("/for-employers")}
              primary
            >
              {strings.nav.for_employers}
            </Button>
          </li>
          <li className="nav-item">
            <NavLink
              className={` ${
                strings.getLanguage() === "arabic" ? "text_ar" : ""
              } nav-link`}
              to="/auth/register"
            >
              {strings.nav.signup}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/auth/login"
              className={` ${
                strings.getLanguage() === "arabic" ? "text_ar" : ""
              } nav-link`}
            >
              {strings.nav.login}
            </NavLink>
          </li>
        </React.Fragment>
      );
    } else {
      return (
        <li className="nav-item">
          <div className="nav-link">
            <div className="d-flex ">
              <div className="user_avatar mx-2">
                {user && user.data && user.data.image ? (
                  <img
                    width={30}
                    height={30}
                    className="user_avatar_image"
                    src={user?.data?.image}
                    alt="profile image"
                  />
                ) : (
                  <FontAwesomeIcon icon={faUser} />
                )}
              </div>
              <div className="align-self-center ">
                <Dropdown
                  text={
                    user?.data?.name
                      ? " " + user?.data?.name + " "
                      : " " + user?.data?.email + " "
                  }
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className={isAr ? "text-right" : ""}
                      onClick={() => history.push("/user/myprofile")}
                      text={strings.nav.myProfile}
                    />
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(Logout());
                        history.push("/");
                      }}
                      text={strings.nav.logout}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </li>
      );
    }
  };

  // onClick={() => dispatch(Logout())}
  console.log({ user });
  return (
    <nav
      className={`navbar nav_white navbar-expand-lg fixed-top ${
        bgWhite ? "nav_white  shadow-sm" : ""
      } ${isTop && !bgWhite ? "" : "nav_white  shadow-sm"}`}
    >
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <img
            width={30}
            height={30}
            src={logo}
            alt="logo"
            style={{ width: "4rem" }}
          />
        </NavLink>
        <div className="navbar-toggler">
          {window.location.pathname != "/" &&
            window.location.pathname != "/companies" && (
              <li
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "0 20px",
                  cursor: "pointer",
                }}
              >
                <SearchModal>
                  <Icon
                    // disabled
                    name="search"
                    size="large"
                    style={{ fontSize: "large" }}
                  />
                </SearchModal>
              </li>
            )}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <FontAwesomeIcon icon={faBars} />
            </span>
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            className={`navbar-nav ${
              lng === "english" ? "mr-auto" : "ml-auto"
            }`}
          >
            <li className="nav-item">
              <NavLink
                to="/companies"
                className={`${
                  strings.getLanguage() === "arabic" ? "text_ar" : ""
                } nav-link`}
              >
                {strings.nav.companys}
                <span className="sr-only">(current)</span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/salaries"
                className={` ${
                  strings.getLanguage() === "arabic" ? "text_ar" : ""
                } nav-link`}
                href="#"
              >
                {strings.nav.salaries}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`${
                  strings.getLanguage() === "arabic" ? "text_ar" : ""
                } nav-link`}
                to={"/studies"}
                tabIndex="-1"
                aria-disabled="true"
              >
                {strings.nav.studies}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={` ${
                  strings.getLanguage() === "arabic" ? "text_ar" : ""
                } nav-link`}
                to="/awards"
                tabIndex="-1"
                aria-disabled="true"
              >
                {strings.nav.awards}
              </NavLink>
            </li>
          </ul>

          <ul
            className={`navbar-nav ${
              lng === "english" ? "ml-auto" : "mr-auto"
            } `}
          >
            {window.location.pathname != "/" &&
              window.location.pathname != "/companies" && (
                <li className="show-search">
                  <SearchModal>
                    <Icon
                      // disabled
                      name="search"
                      size="large"
                      style={{ fontSize: "large" }}
                    />
                  </SearchModal>
                </li>
              )}
            {renderIsAuth()}
            <li
              className="nav-item font-weight-bold"
              // style={isTransform && token ? { marginTop: "3px" } : {}}
            >
              <a
                onClick={onChangeLang}
                className={` ${
                  strings.getLanguage() !== "arabic" ? "text_ar" : ""
                } nav-link`}
                href="#"
                // style={isTransform && token ? { fontSize: "15px" } : {}}
              >
                {selected}
                <Flag
                  className="m-1"
                  name={`${lng === "english" ? "sa" : "us"}`}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
