import React, { useRef, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import strings from "../Localization/LNG";
import { closeShare } from "../redux/actions/uiActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import "./style.css";
function ShareModal() {
  const { open } = useSelector((state) => state.ui.share);
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const dispatch = useDispatch();
  const [isCoped, setisCoped] = useState(false);
  const onClose = () => {
    dispatch(closeShare());
  };
  const lang = localStorage.getItem("lng");
  const inputRef = useRef();
  var url = window.location.href;

  return (
    <Modal
      show={open}
      keyboard={true}
      animation={false}
      centered
      size="sm"
      dir={`${isAr ? "rtl" : "ltr"}`}
      onEscapeKeyDown={onClose}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{}}>{strings.shared.share}</Modal.Title>
      </Modal.Header>
      <div
        className="m-5"
        // style={{ marginLeft: "-56px", border: "1px solid red" }}
      >
        <div className="">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            style={{
              fontSize: "1.3rem",
              background: "#4267B2",
              padding: "10px 20px",
              borderRadius: "6px",
            }}
            target="_blank"
            className={
              lang === "arabic"
                ? "d-flex flex-row-reverse justify-content-center"
                : "d-flex  justify-content-center"
            }
          >
            <div style={{ color: "white" }}>
              <FontAwesomeIcon style={{ color: "white" }} icon={faFacebookF} />
            </div>
            <div style={{ color: "white" }} className="ml-4">
              Facebook
            </div>
          </a>
        </div>
        <div className="share_link_copy mt-3 d-flex flex-column align-items-center">
          <span className="text_muted" style={{ fontSize: "12px" }}>
            {isCoped
              ? lang === "english"
                ? "Link copied"
                : "تم النسخ"
              : lang === "english"
              ? "Click to copy link"
              : "اضغط لنسخ الرابط"}
          </span>
          <input
            readonly="readonly"
            ref={inputRef}
            onClick={(e) => {
              setisCoped(true);
              inputRef.current.select();
              document.execCommand("copy");
            }}
            value={url}
            type="text"
            className="share_input"
            style={{ maxWidth: "100%" }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ShareModal;
