const INITIAL_STATE = {
  boundedCompany: null,
};

const boundCompanyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "BOUND":
      return {
        ...state,
        boundedCompany: action.payload,
      };
    case "UN_BOUND":
      return null;
    default:
      return state;
  }
};

export default boundCompanyReducer;
