import React, { useEffect, useState } from "react";
import Navbar from "../../../../Components/Navbar/Navbar";
import PageWraper from "../../../../Shared/PageWraper";
import strings from "../../../../Localization/LNG";
import { Button, Col, Container, Row } from "react-bootstrap";
import Footer from "../../../../Components/Footer/Footer";
import styled from "styled-components";
import { Checkbox, Form, Input, Radio, Select } from "antd";
import enstanse, { BASE_URL } from "../../../../utils/axios";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const PageRateHeader = styled.div`
  /* background-image: "../../assets/images/Group\ 20.png" !important ; */
`;
function UpdatedInterviewReview() {
  const [companyProfile, setCompanyProfile] = useState(null);
  const [lang, setLang] = useState(window.localStorage.getItem("lng"));
  const { token } = useSelector((state) => state.auth);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const [employmentStatus, setEmploymentStatus] = useState([]);
  const { Option } = Select;
  const getEmpolymentStatus = async () => {
    const res = await enstanse.get(`/employment/status`);
    setEmploymentStatus(res.data.data);
  };
  const [citys, setCitys] = useState([]);

  const getCitys = async (value) => {
    const params = {};
    if (value) {
      params.country_id = value;
    }
    const res = await enstanse.get("/cities", {
      params,
    });
    setCitys(res.data.data);
  };
  console.log({ companyProfile });
  const params = useParams();
  const [levels, setJobLevels] = useState([]);
  const getJobLevel = async () => {
    const res = await enstanse.get(`/joblevels`);
    setJobLevels(res.data.data);
  };
  const [jobTitle, setJobTitle] = useState([]);
  const getJobsTtile = async () => {
    const res = await enstanse.get(`/jobtitles`);
    setJobTitle(res.data.data);
  };
  const [interviewTypes, setInterviewTypes] = useState([]);
  const [interviewTest, setInterviewTest] = useState([]);
  const getStages = async () => {
    const res = await enstanse.get("/interview/stages");
    const data = res.data.data;
    const { interview, other, testing } = data;
    setInterviewTest(interview);
    setInterviewTypes(testing);
    // setOthers(other);
    // setStages(dep);
  };

  var month = [
    { name: "January", key: "January", id: "January" },
    { name: "February", key: "February", id: "February" },
    { name: "March", key: "March", id: "March" },
    { name: "April", key: "April", id: "April" },
    { name: "May", key: "May", id: "May" },
    { name: "April", key: "April", id: "April" },
    { name: "June", key: "June", id: "June" },
    { name: "July", key: "July", id: "July" },
    { name: "September", key: "September", id: "September" },
    { name: "October", key: "October", id: "October" },
    { name: "November", key: "November", id: "November" },
    { name: "December", key: "December", id: "December" },
  ];
  const [isFormer, setIsFormer] = useState("0");

  const [countrys, setCountrys] = useState([]);

  const getCountrys = async () => {
    const res = await enstanse.get("/countries");
    setCountrys(res.data.data);
  };
  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );
  const rangedValues = range(currentYear, currentYear - 50, -1);
  let years = [];
  rangedValues.forEach((n) => years.push({ id: n, name: n, key: n }));

  const yearOptions = years;
  const [departments, setDepartments] = useState([]);
  const getDepartment = async () => {
    const res = await enstanse.get("/departments");
    setDepartments(res.data.data);
  };
  const [getOffer, setGetOffer] = useState(null);
  useEffect(() => {
    enstanse
      .get(`companies/${params.id}/profile`)
      .then((res) => setCompanyProfile(res.data.data));
    getEmpolymentStatus();
    getJobLevel();
    getJobsTtile();
    getStages();
    getCountrys();
    getCitys(133);
    getDepartment();
  }, []);
  return (
    <div className="company_overview">
      <Helmet>
        <link rel="canonical" href="/interview-review/:id" />
        <meta
          property="og:title"
          content={`${strings.siteName} - ${strings.nav.signup}`}
        />
        <meta property="og:url" content={`${BASE_URL}/interview-review/:id`} />
        <title>
          {strings.siteName} - {strings.nav.signup}
        </title>
      </Helmet>
      <style>
        {`.custom-radio-group .ant-form-item-control-input-content{
          display:flex;
        }`}
      </style>
      <Navbar bgWhite />
      <PageWraper>
        <PageRateHeader className="rate_page bg_gray" style>
          <div
            className="content text-center pt-5 pb-5"
            style={{ height: "12rem" }}
          >
            <h3 className="font-weight-bold regiseter_title">
              {strings.submitReview.wouldLikeToContribute}
            </h3>
          </div>
        </PageRateHeader>
        <Container
          className="my-5"
          style={{
            padding: "65px 15px 15px",
            background: "white",
            transform: "translateY(-100px)",
            borderRadius: "20px",
            boxShadow: "rgb(0 0 0 / 22%) 0px 0px 13px -2px",
          }}
        >
          <Form
            layout="vertical"
            onFinish={(valuesParam) => {
              const values = {
                ...valuesParam,
              };
              console.log({ values });

              const selectedStages = [];
              if (values.interviewtype) {
                selectedStages.push(values.interviewtype);
                delete values.interviewtype;
              }
              if (values.interviewtesting) {
                selectedStages.push(values.interviewtesting);
              }
              if (values.interviewOther) {
                selectedStages.push(values.interviewOther);
              }
              values.stages = selectedStages;

              values.accept_terms = +values.accept_terms;
              values.employer_name = companyProfile.data.name;

              console.log({ values });
              values.employer_name = toast.promise(
                enstanse.post(`interview/review`, values, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }),
                {
                  pending: isAr ? "جاري المعالجة " : "Pending",
                  success: {
                    render(res) {
                      setSubmitting(false);
                      history.push(`/company/${params.id}/interviews`);

                      return `👌 ${res.data.data.message}`;
                    },
                    // other options
                    icon: "🟢",
                  },
                  error: {
                    render(err) {
                      setSubmitting(false);
                      // history.push(`/company/${params.id}/interviews`);
                      return `  ${
                        err?.data?.response?.data?.message ||
                        "Backend Error Occured"
                      }`;
                    },
                    // other options
                    //    icon: "🟢",
                  },
                }
              );
            }}
            initialValues={{
              nationality: "",
              city: "",
              country_id: 133,
              specilization: "",
              education: "",
              gender: "",
              education: "",
              // benefits,
            }}
          >
            <Row>
              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  className="custom-radio-group"
                  name="get_offer"
                  label={strings.submitReview.didYougetAnOffer}
                  rules={[
                    {
                      required: true,
                      message: strings.submitReview.didYougetAnOffer,
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      setGetOffer(e.target.value);
                    }}
                  >
                    <Radio value="yes">{strings.submitReview?.yes}</Radio>
                    <Radio value="no">{strings.submitReview?.no}</Radio>
                    <Radio value="decline">
                      {strings.submitReview?.decline}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {getOffer == "yes" && (
                <Col xs={12} sm={12} lg={6} xl={6}>
                  <Form.Item
                    className="custom-radio-group"
                    name="is_former"
                    label={strings.submitReview.formerOrEmpoloyeer}
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.formerOrEmpoloyeer,
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        setIsFormer(e.target.value);
                      }}
                    >
                      <Radio value="1">{strings.submitReview.former}</Radio>
                      <Radio value="0">{strings.submitReview.current}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}

              {isFormer == 1 && (
                <Col xs={12} sm={12} lg={6} xl={6}>
                  <Form.Item
                    label={strings.submitReview.latsYearEmpolyee}
                    name="last_year"
                    rules={[
                      {
                        required: true,
                        message: strings.submitReview.enterLatsYearEmpolyee,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      loading={!yearOptions.length}
                      style={{
                        textAlign: isAr ? "right" : "left",
                      }}
                      placeholder={strings.shared.select}
                      className="custom-select"
                      size="large"
                      allowClear
                    >
                      {yearOptions?.map((job) => (
                        <Option value={job.id}>{job?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.submitReview.employmentStatus}
                  name="employment_status"
                  rules={[
                    {
                      required: true,
                      message: strings.user.select_employment_Status,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    loading={!employmentStatus.length}
                    optionFilterProp="children"
                    style={{
                      textAlign: isAr ? "right" : "left",
                    }}
                    placeholder={strings.shared.select}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {employmentStatus?.map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.user.job_level}
                  name="joblevel"
                  rules={[
                    {
                      required: true,
                      message: strings.user.select_job_title,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    loading={!levels.length}
                    optionFilterProp="children"
                    style={{
                      textAlign: isAr ? "right" : "left",
                    }}
                    placeholder={strings.user.job_level}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {levels?.map((department) => (
                      <Option value={department.id}>{department?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.auth.department}
                  name="department"
                  rules={[
                    {
                      required: true,
                      message: strings.auth.validation.departmentRequired,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    loading={!departments.length}
                    optionFilterProp="children"
                    style={{
                      textAlign: isAr ? "right" : "left",
                    }}
                    placeholder={strings.auth.department}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {departments?.map((department) => (
                      <Option value={department.id}>{department?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.auth.jobTitle}
                  name="jobtitle"
                  rules={[
                    {
                      required: true,
                      message: strings.auth.select_job_title,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    loading={!jobTitle.length}
                    optionFilterProp="children"
                    style={{
                      textAlign: isAr ? "right" : "left",
                    }}
                    placeholder={strings.auth.jobTitle}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {jobTitle?.map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  className="custom-radio-group"
                  name="rate"
                  label={strings.submitReview.rate}
                  rules={[
                    {
                      required: true,
                      message: strings.submitReview.rate,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="positive">
                      {strings.submitReview.positive}
                    </Radio>
                    <Radio value="negative">
                      {strings.submitReview.negative}
                    </Radio>
                    <Radio value="no_opinion">
                      {strings.submitReview.no_opinion}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.shared.month}
                  name="month"
                  rules={[{ required: true, message: strings.shared.month }]}
                >
                  <Select
                    showSearch
                    loading={!month.length}
                    optionFilterProp="children"
                    style={{ textAlign: isAr ? "right" : "left" }}
                    placeholder={strings.shared.month}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {month?.map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.shared.year}
                  name="year"
                  rules={[{ required: true, message: strings.shared.year }]}
                >
                  <Select
                    showSearch
                    loading={!yearOptions.length}
                    optionFilterProp="children"
                    style={{ textAlign: isAr ? "right" : "left" }}
                    placeholder={strings.shared.year}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {yearOptions?.map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.submitReview.getBy}
                  name="get_by"
                  rules={[
                    { required: true, message: strings.submitReview.getBy },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ textAlign: isAr ? "right" : "left" }}
                    placeholder={strings.submitReview.getBy}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {[
                      {
                        name: strings.submitReview.appliedOnline,
                        key: "applied_online",
                        id: "applied_online",
                      },
                      {
                        name: strings.submitReview.collecgeOrUn,
                        key: "college_or_university",
                        id: "college_or_university",
                      },
                      {
                        name: strings.submitReview.employeeReferral,
                        key: "employee_referral",
                        id: "employee_referral",
                      },
                      {
                        name: strings.submitReview.inPerson,
                        key: "in_person",
                        id: "in_person",
                      },
                      {
                        name: strings.submitReview.Recruiter,
                        key: "recruiter",
                        id: "recruiter",
                      },
                      {
                        name: strings.submitReview.staffingAgency,
                        key: "staffing_agency",
                        id: "staffing_agency",
                      },
                      {
                        name: strings.submitReview.others,
                        key: "other",
                        id: "other",
                      },
                    ].map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* <p className="font-weight-bold h6 mb-3 mt-3">
                {strings.submitReview.Whatstageswhererequired}
              </p> */}

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.submitReview.Testing}
                  name="interviewtype"
                  rules={[
                    { required: true, message: strings.submitReview.Testing },
                  ]}
                >
                  <Select
                    showSearch
                    loading={!interviewTypes.length}
                    optionFilterProp="children"
                    style={{ textAlign: isAr ? "right" : "left" }}
                    placeholder={strings.submitReview.Testing}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {interviewTypes?.map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.submitReview.InterviewType}
                  name="interviewtesting"
                  rules={[
                    {
                      required: true,
                      message: strings.submitReview.InterviewType,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    loading={!interviewTest.length}
                    optionFilterProp="children"
                    style={{ textAlign: isAr ? "right" : "left" }}
                    placeholder={strings.submitReview.InterviewType}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {interviewTest?.map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.submitReview.difficulty}
                  name="difficulty"
                  rules={[
                    {
                      required: true,
                      message: strings.submitReview.difficulty,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ textAlign: isAr ? "right" : "left" }}
                    placeholder={strings.submitReview.difficulty}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {[
                      {
                        name: strings.submitReview.very_easy,
                        id: "very_easy",
                        key: "very_easy",
                      },
                      {
                        name: strings.submitReview.easy,
                        id: "easy",
                        key: "easy",
                      },
                      {
                        name: strings.submitReview.average,
                        id: "average",
                        key: "average",
                      },
                      {
                        name: strings.submitReview.difficult,
                        id: "difficult",
                        key: "difficult",
                      },
                      {
                        name: strings.submitReview.very_difficult,
                        id: "very_difficult",
                        key: "very_difficult",
                      },
                    ].map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.submitReview.cashBonus}
                  name="duration"
                  rules={[
                    {
                      required: true,
                      message: strings.submitReview.cashBonusDuration,
                    },
                  ]}
                >
                  <Input
                    type={"number"}
                    size="large"
                    style={{ borderRadius: 10 }}
                    className="custom-input"
                    name="duration"
                    placeholder={strings.submitReview.cashBonusDuration}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.submitReview.cashBonusDuration}
                  name="duration_type"
                  rules={[
                    {
                      required: true,
                      message: strings.submitReview.duration_type,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{
                      textAlign: isAr ? "right" : "left",
                    }}
                    placeholder={strings.submitReview.duration_type}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {[
                      // { text: strings.shared.year, key: "year", value: "year" },
                      { name: strings.shared.day, key: "day", id: "day" },
                      { name: strings.shared.week, key: "week", id: "week" },
                      {
                        name: strings.shared.month,
                        key: "month",
                        id: "month",
                      },
                    ].map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.country}
                  name="country_id"
                  rules={[
                    {
                      required: true,
                      message: strings.country,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    loading={!countrys.length}
                    optionFilterProp="children"
                    style={{
                      textAlign: isAr ? "right" : "left",
                    }}
                    placeholder={strings.country}
                    className="custom-select"
                    size="large"
                    allowClear
                    defaultValue={133}
                  >
                    <Option value={133}>
                      {lang === "english"
                        ? "Saudi Arabia"
                        : "المملكة العربية السعودية"}
                    </Option>
                    {/* {countrys?.map((job) => (
                      ))} */}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.auth.city}
                  name="city_id"
                  rules={[
                    {
                      required: true,
                      message: strings.auth.city,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    loading={!citys.length}
                    optionFilterProp="children"
                    style={{
                      textAlign: isAr ? "right" : "left",
                    }}
                    placeholder={strings.auth.city}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {citys?.map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.submitReview.how_helpful}
                  name="how_helpful"
                  rules={[
                    {
                      required: true,
                      message: strings.submitReview.how_helpful,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ textAlign: isAr ? "right" : "left" }}
                    placeholder={strings.submitReview.how_helpful}
                    className="custom-select"
                    size="large"
                    allowClear
                  >
                    {[
                      {
                        name: strings.submitReview.very_helpful,
                        id: "very_helpful",
                        key: "very_helpful",
                      },
                      {
                        name: strings.submitReview.helpful,
                        id: "helpful",
                        key: "helpful",
                      },
                      {
                        name: strings.submitReview.not_helpful,
                        id: "not_helpful",
                        key: "not_helpful",
                      },
                      {
                        name: strings.submitReview.not_use_it,
                        id: "not_use_it",
                        key: "not_use_it",
                      },
                    ].map((job) => (
                      <Option value={job.id}>{job?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={12} sm={12} lg={6} xl={6}>
                <Form.Item
                  label={strings.submitReview.description}
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: strings.submitReview.description,
                    },
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    style={{ borderRadius: 10 }}
                    className="custom-input"
                    name="description"
                    placeholder={strings.submitReview.description}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} sm={12} lg={12} xl={12}>
                <Form.List name="questions">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                            width: "100%",
                            gap: "10px",
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            question
                            style={{ width: "50%" }}
                            label={strings.submitReview.question}
                            name={[name, "question"]}
                            rules={[
                              {
                                required: true,
                                message: strings.submitReview.question,
                              },
                            ]}
                          >
                            <Input.TextArea
                              placeholder={strings.submitReview.question}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            style={{ width: "50%" }}
                            label={strings.submitReview.answer}
                            name={[name, "answer"]}
                            rules={[
                              {
                                required: true,
                                message: strings.submitReview.answer,
                              },
                            ]}
                          >
                            <Input.TextArea
                              placeholder={strings.submitReview.answer}
                            />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      ))}
                      <Form.Item>
                        <a
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <p
                              style={{
                                background: "#0089EF",
                                width: "200px",
                                textAlign: "center",
                                padding: "10px 0px",
                                borderRadius: "10px",
                                color: "white",
                              }}
                            >
                              {strings.submitReview.selecectQuestion}
                            </p>
                          </div>
                        </a>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>

              <Col xs={12} sm={12} lg={12} xl={12}>
                <Form.Item
                  className="custom-radio-group"
                  name="accept_terms"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: strings.submitReview.required,
                    },
                  ]}
                >
                  <Checkbox>
                    {strings.utils.iAgreetaakkad}
                    <Link target="_blank" to="/terms" className="link_color">
                      {" "}
                      {strings.utils.termsOfUse}{" "}
                    </Link>
                    {strings.utils.reviewTermText}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <div className="d-flex">
              <Form.Item className="mx-2">
                <Button
                  style={{ borderRadius: "5px" }}
                  type="primary"
                  htmlType="submit"
                >
                  {isAr ? "ارسال" : "Submit"}
                </Button>
              </Form.Item>
              <Form.Item className="mx-2">
                <Button
                  style={{
                    borderRadius: "5px",
                    background: "white",
                    color: "#0089EF",
                  }}
                  htmlType="button"
                  onClick={() => {
                    history.push(`/company/${params.id}/interviews`);
                    // setIsSelected((prev) => !prev);
                    // setSelectedCard(null);
                  }}
                >
                  {isAr ? "إلغاء" : "Cancel"}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Container>
      </PageWraper>
      <Footer />
    </div>
  );
}

export default UpdatedInterviewReview;
