import React, { useState, useEffect } from "react";
import strings from "../../Localization/LNG";
import { Link } from "react-router-dom";
import axios from "../../utils/axios";
import { Modal } from "antd";
import { toast } from "react-toastify";
export default function Footer({ setIsModalVisible2 }) {
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  const [data, setData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  useEffect(() => {
    axios.get(`/settings`).then((res) => {
      const data = {
        instagram_url: res.data.data.filter(
          (el) => Object.keys(el)[0] === "instagram_url"
        )[0].instagram_url,
        twitter_url: res.data.data.filter(
          (el) => Object.keys(el)[0] === "twitter_url"
        )[0].twitter_url,
        facebook_url: res.data.data.filter(
          (el) => Object.keys(el)[0] === "facebook_url"
        )[0].facebook_url,
        youtube_url: res.data.data.filter(
          (el) => Object.keys(el)[0] === "youtube_url"
        )[0].youtube_url,
        phone: res.data.data.filter((el) => Object.keys(el)[0] === "phone")[0]
          .phone,
        email: res.data.data.filter((el) => Object.keys(el)[0] === "email")[0]
          .email,
        snap: res.data.data.filter(
          (el) => Object.keys(el)[0] === "snapchat_url"
        )[0].snapchat_url,
      };

      setData(data);
    });
  }, []);

  const [soonModal, setSoonModal] = useState(false);

  return (
    <>
      <footer className="footer p-5 " id="footer">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-3">
              <img
                width={30}
                height={30}
                loading="lazy"
                src={require("../../assets/images/taakkad-logo.svg")}
                className="img-fluid logo"
                alt="taakkad logo"
              />
              <div
                className="store_icons d-flex mt-3"
                style={{ justifyContent: "space-evenly" }}
              >
                {/* <a href="https://play.google.com/store/apps/details?id=appssquare.projects.takaad"> */}
                <div
                  onClick={() =>
                    toast.info(`${isAr ? "قريباً" : "Comming soon"}`)
                  }
                >
                  <img
                    width={30}
                    height={30}
                    src={require("../../assets/Google_Play_Store_badge_EN.svg")}
                    alt="google play"
                    style={{ width: "100px" }}
                  />
                </div>
                {/* </a> */}
                {/* <a href="https://www.apple.com/app-store/"> */}
                <div
                  onClick={() =>
                    toast.info(`${isAr ? "قريباً" : "Comming soon"}`)
                  }
                >
                  <img
                    width={30}
                    height={30}
                    src={require("../../assets/640px-Download_on_iTunes.svg.png")}
                    alt="app store"
                    style={{ width: "100px", height: "29px" }}
                  />
                </div>
                {/* </a> */}
              </div>
            </div>
            <div
              className={`col-md-3 ${
                isAr
                  ? "text-md-right text-sm-center"
                  : "text-md-left text-sm-center"
              } `}
            >
              <div className="">
                <p
                  className="col_title text-uppercase  font-weight-bold"
                  style={{ fontWeight: "900", fontSize: "25px" }}
                >
                  {strings.footer.ourCompany}
                </p>
                <ul className="p-0">
                  <li>
                    <Link to="/about-us"> {strings.footer.aboutus}</Link>
                  </li>
                  {/* <li>
                    <Link to="/press"> {strings.footer.press} </Link>{" "}
                  </li> */}
                  <li>
                    <Link to={"/terms"}> {strings.footer.terms} </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      {strings.footer.privacyPolicy}
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq"> {strings.footer.faq} </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`col-md-3 ${
                isAr
                  ? "text-md-right text-sm-center"
                  : "text-md-left text-sm-center"
              } `}
            >
              <div className="">
                <p
                  className="col_title text-uppercase font-weight-bold"
                  style={{ fontWeight: "900", fontSize: "25px" }}
                >
                  {strings.footer.product}
                </p>
                <ul className="p-0">
                  <li>
                    <Link to="/"> {strings.footer.home} </Link>
                  </li>
                  <li>
                    <Link to="/companies"> {strings.nav.companys} </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/salaries"> {strings.nav.salaries} </Link>
                  </li>
                  <li>
                    <Link to="/studies"> {strings.nav.studies} </Link>{" "}
                  </li>
                  <li>
                    <Link to="/awards"> {strings.nav.awards} </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`col-md-3 ${
                isAr
                  ? "text-md-right text-sm-center"
                  : "text-md-left text-sm-center"
              } `}
            >
              <div className="">
                <p
                  className="col_title text-uppercase font-weight-bold"
                  style={{ fontWeight: "900", fontSize: "25px" }}
                >
                  {strings.footer.contact}
                </p>
                <ul className="p-0">
                  <li>
                    <a href={`mailto:${data?.email}`}>
                      {" "}
                      {strings.email} : {data?.email}
                    </a>
                  </li>
                  {/* <li>
             
                    <a
                      onClick={() =>
                        toast.info(isAr ? "متاح قريباً" : "Comming Soon")
                      }
                    >
                      {strings.telephone} :
                      <p style={{ direction: "ltr", display: "inline-table" }}>
                        {data?.phone}
                      </p>
                    </a>
                  </li> */}
                  <p className="get_in_touch mt-4 text-uppercase">
                    {strings.GitinTouch}
                  </p>
                </ul>
                <div className="socile_link mt-3">
                  {data.twitter_url && (
                    <div className="icons">
                      <a
                        target="_blank"
                        title="twitter"
                        href={data.twitter_url}
                      >
                        <img
                          width={30}
                          height={30}
                          loading="lazy"
                          src={require("../../assets/icons/social/twitter-1.svg")}
                          alt="twitter"
                        />
                      </a>
                    </div>
                  )}
                  {data?.instagram_url && (
                    <div className="icons">
                      <a
                        target="_blank"
                        title="instagram"
                        href={data.instagram_url}
                      >
                        <img
                          width={30}
                          height={30}
                          loading="lazy"
                          src={require("../../assets/icons/social/instagram.svg")}
                          alt="instagram"
                        />
                      </a>
                    </div>
                  )}
                  {data.snap && (
                    <div className="icons">
                      <a target="_blank" title="snapchat" href={data.snap}>
                        <img
                          width={30}
                          height={30}
                          loading="lazy"
                          src={require("../../assets/icons/social/snapchat.svg")}
                          alt="snapchat"
                        />
                      </a>
                    </div>
                  )}

                  {/* <div className="icons">
                    <a
                      target="_blank"
                      title="facebook"
                      href={data.facebook_url}
                    >
                      <img
                        loading="lazy"
                        src={require("../../assets/icons/social/facebook.svg")}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="icons">
                    <a target="_blank" title="youtube" href={data.youtube_url}>
                      <img
                        loading="lazy"
                        src={require("../../assets/icons/social/youtube.svg")}
                        alt=""
                      />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {isAr ? (
        <div
          className="copy_right p-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ margin: "0px" }}>{strings.copy_right} </p>
          <p style={{ margin: "0px 5px" }}>&#169;</p>
          <p style={{ margin: "0px" }}>{new Date().getFullYear()}</p>
        </div>
      ) : (
        <div
          className="copy_right p-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ margin: "0px" }}>&#169;</p>
          <p style={{ margin: "0px 5px" }}>{new Date().getFullYear()}</p>
          <p style={{ margin: "0px" }}>{strings.copy_right} </p>
        </div>
      )}
    </>
  );
}
