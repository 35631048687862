import { Checkbox, Form, Input, Rate } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import strings from "../../../../../Localization/LNG";

function CompanyReview({ companyProfile }) {
  const isAr = strings.getLanguage() === "arabic" ? true : false;
  return (
    <Row>
      <Col className="survey-form-item" xs={12} sm={12} lg={12} xl={12}>
        <Form.Item
          name="rate"
          label={strings.submitReview.overAllRating}
          rules={[
            {
              required: true,
              message: strings.submitReview.overAllRating,
            },
          ]}
        >
          <Rate />
        </Form.Item>
      </Col>
      <Col className="survey-form-item" xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.pros}
          name="pros"
          rules={[
            {
              required: true,
              message: strings.submitReview.pros,
            },
            {
              max: 500,
              message: isAr
                ? "يجب ألا يقل الايجابيات عن 3 أحرف بحد أدنى و 500 حرف كحد أقصى."
                : "Pros should be a minimum of 3 characters and a maximum of 500 characters.",
            },
            {
              min: 3,
              message: isAr
                ? "يجب ألا يقل الايجابيات عن 3 أحرف بحد أدنى و 500 حرف كحد أقصى."
                : "Pros should be a minimum of 3 characters and a maximum of 500 characters.",
            },
          ]}
        >
          <Input.TextArea
            size="large"
            style={{ borderRadius: 10, width: "100%" }}
            className="custom-input"
            name="pros"
            placeholder={`${strings.submitReview.prosPlaceholder} ${
              strings.getLanguage() === "arabic"
                ? companyProfile?.name_ar
                  ? companyProfile?.name_ar
                  : companyProfile?.name_en
                : companyProfile?.name_en
            }`}
          />
        </Form.Item>
      </Col>
      <Col xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.cons}
          name="cons"
          rules={[
            {
              required: true,
              message: strings.submitReview.cons,
            },
          ]}
        >
          <Input.TextArea
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="cons"
            placeholder={`${strings.submitReview.consPlaseHolder} ${
              strings.getLanguage() === "arabic"
                ? companyProfile?.name_ar
                  ? companyProfile?.name_ar
                  : companyProfile?.name_en
                : companyProfile?.name_en
            }`}
          />
        </Form.Item>
      </Col>
      <Col xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.good_Words}
          name="good_word"
          rules={[
            {
              pattern:
                "^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$",
              message: strings.submitReview.one_word_msg,
            },
            {
              required: true,
              message: strings.submitReview.good_Words,
            },
          ]}
        >
          <Input.TextArea
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="good_word"
            placeholder={`${strings.submitReview.goodWordsPlceholder} ${
              strings.getLanguage() === "arabic"
                ? companyProfile?.name_ar
                  ? companyProfile?.name_ar
                  : companyProfile?.name_en
                : companyProfile?.name_en
            }`}
          />
        </Form.Item>
      </Col>
      <Col xs={12} sm={12} lg={6} xl={6}>
        <Form.Item
          label={strings.submitReview.bad_words}
          name="bad_word"
          rules={[
            {
              // pattern: "^S+$",
              pattern:
                "^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$",

              message: strings.submitReview.one_word_msg,
            },
            {
              required: true,
              message: strings.submitReview.bad_words,
            },
            // {
            //   max: 500,
            //   message: isAr
            //     ? "يجب ألا يقل السلبيات عن 3 أحرف بحد أدنى و 500 حرف كحد أقصى."
            //     : "Cons should be a minimum of 3 characters and a maximum of 500 characters.",
            // },
            // {
            //   min: 3,
            //   message: isAr
            //     ? "يجب ألا يقل السلبيات عن 3 أحرف بحد أدنى و 500 حرف كحد أقصى."
            //     : "Cons should be a minimum of 3 characters and a maximum of 500 characters.",
            // },
          ]}
        >
          <Input.TextArea
            size="large"
            style={{ borderRadius: 10 }}
            className="custom-input"
            name="bad_word"
            placeholder={`${strings.submitReview.badWordPlaceholder} ${
              strings.getLanguage() === "arabic"
                ? companyProfile?.name_ar
                  ? companyProfile?.name_ar
                  : companyProfile?.name_en
                : companyProfile?.name_en
            }`}
          />
        </Form.Item>
      </Col>
      <Col xs={12} sm={12} lg={12} xl={12}>
        <Form.Item
          name="accept_terms"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: strings.submitReview.required,
            },
          ]}
        >
          <Checkbox>
            {strings.utils.iAgreetaakkad}
            <Link target="_blank" to="/terms" className="link_color">
              {" "}
              {strings.utils.termsOfUse}{" "}
            </Link>
            {strings.utils.reviewTermText}
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
}

export default CompanyReview;
